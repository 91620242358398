
import { CookieStorage } from 'cookie-storage';
import { deleteCookie, getCookie, listCookieKeys, setCookie } from '../utils/deep-proxy.function';
import { AsyncStorage, WebStorageStateStore } from 'oidc-client-ts';

export class CookieStorageStateStore {
  private pr = "oidc."
  /**
   *
   */
  constructor(private environmentUrl: string) {
    
  }
  set(key: any, value: any) {

    key = this.pr + key;

    setCookie(key, value);

    return Promise.resolve();
  }

  get(key: string) {
    
    let usingKey = key;
    if(usingKey=== `user:https://itvance.identity${this.environmentUrl}.itvanceplatform.com:undefined`){
      var res = listCookieKeys(this.pr).filter(x => x.startsWith('user:'));
      if(res.length === 0) return undefined;
      usingKey = res[0]
    }
    usingKey = this.pr + usingKey;
    let item = getCookie(usingKey);

    return Promise.resolve(item);
  }

  remove(key: string) {

    key = this.pr + key;

    let item = getCookie(key);
    deleteCookie(key);

    return Promise.resolve(item);
  }

  getAllKeys() {


    return Promise.resolve(listCookieKeys(this.pr));
  }
}
export default CookieStorageStateStore;
