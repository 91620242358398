require('./assets/styles/main.css');
import { setupRoutes } from './utils/router';
import OAuth from './auth/oauth';

import { configureState } from './state/configureState';
import GrapesJs from './grapesjs/grapesjs';
import { IWebsiteState } from './models/website-state.model';
import SiteDataLoadingService from './page/index/website.service';
import HttpClient from './utils/httpclient.service';
import { LoaderManager } from './utils/loader/loader';
import { FileService } from './files/file.service';


document.addEventListener('DOMContentLoaded', async () => {
    const state = configureState();


    // Initialize OAuth
    // You may want to add logic here to check the current token state, handle redirections, etc.
    // For example:

    const url = location.host === "localhost" ? "http" : "https";
    const oauth = new OAuth(state.dataSources?.websiteData?.websiteId, `${url}://${location.host}/auth/callback`, `${url}://${location.host}/auth/silentRefresh`, `${url}://${location.host}/auth/callbackLogout`, state.dataSources?.websiteData?.authority ?? `https://itvance.identity${document.dataSources.environmentUrl}.itvanceplatform.com`, "openid offline_access profile email projects files employees registrations cms roles", state);

    const user = await oauth.getUserFromToken();

    state.dataSources.user = user;

    var httpClient = new HttpClient(oauth);
    document.httpClient = httpClient;
    var websiteService = new SiteDataLoadingService(httpClient, state);
    var fileService = new FileService(httpClient, state);
    document.fileService = fileService;
    if (state.dataSources.isServerSide) {
        websiteService.startLoadingWebsiteData(state);
    }

    const websiteData = await websiteService.getLoadedWebsiteData();

    state.dataSources.websiteData = websiteData;
    var grapesJs = new GrapesJs(state, websiteService, fileService, httpClient);
    if (state.dataSources.isClientSide) {

        if (websiteData.pwaDefinition) {

            if ("serviceWorker" in navigator && "SyncManager" in window) {
                window.addEventListener("load", () => {
                    navigator.serviceWorker
                        .register("/service-worker.js", { scope: "/" })
                        .then(async (registration) => {
                            console.log("Service Worker registered:", registration);
                            debugger;
                            // check if Storage API is supported
                            const supported = 'storage' in navigator;

                            // check if persistent storage is supported
                            const persistentStorageSupported = navigator.storage && navigator.storage.persist;

                            // request persistent storage
                            const persist = await navigator.storage.persist();
                            debugger;
                        })
                        .catch((registrationError) => {
                            console.error("Service Worker registration failed:", registrationError);
                        });
                });
            }

            // load  worker service /pwa/service-worker.ts here
        }

    } else {
        document.title = websiteData.displayName;

        if (!state.dataSources.editorEnabled && websiteData?.script && websiteData.script.length > 0 && !document.getElementById('global-script')) {
            const script = document.createElement('script');
            // Optional: Add a type attribute to the style element (not necessary in HTML5)
            script.type = 'application/javascript';
            script.src = `${websiteData.script}?dontPreload=true`;
            script.id = "global-script";
            document.body?.appendChild(script);

        }

        if (!state.dataSources.editorEnabled && websiteData?.externalScripts && websiteData.externalScripts.length > 0) {
            websiteData.externalScripts.forEach((x: any, index: number) => {
                const key = `global-script-${index}`
                if (!document.getElementById(key)) {

                    const script = document.createElement('script');
                    // Optional: Add a type attribute to the style element (not necessary in HTML5)
                    script.type = 'application/javascript';
                    script.src = `${x}?dontPreload=true`;
                    script.id = key;
                    document.body?.appendChild(script);
                }
            });
        }

        if (!document.getElementById('global-css') && websiteData?.css && websiteData?.css.length) {
            var style = document.createElement('link');
            // Optional: Add a type attribute to the style element (not necessary in HTML5)
            style.href = websiteData.css;
            style.rel = 'stylesheet';
            style.id = "global-css";
            //style.appendChild(document.createTextNode(await response.text()));
            document.head.appendChild(style);
        }
        if (websiteData.pwaDefinition) {
            const manifestLink = document.createElement("link");
            manifestLink.rel = "manifest";
            manifestLink.href = websiteData.pwaDefinition; // Change this to your dynamic URL if needed
            document.head.appendChild(manifestLink);


            // load  worker service /pwa/service-worker.ts here
        }


        if (websiteData?.externalCss && websiteData.externalCss.length > 0) {
            websiteData.externalCss.forEach((x: any, index: number) => {
                var style = document.createElement('link');
                const key = `global-css-${index}`
                if (!document.getElementById(key)) {

                    style.rel = 'stylesheet';
                    style.id = key;
                    style.href = x;
                    //style.appendChild(document.createTextNode(await response.text()));
                    document.head.appendChild(style);
                }
            });

        }
    }





    setupRoutes(oauth, state, grapesJs, websiteService,
        () => new Promise(async (resolve) => {

            LoaderManager.start();
            if (state.dataSources.isClientSide && state.dataSources.initialClientRendering) {
                resolve();
                return;
            }

            if (state.dataSources.isClientSide) {

                state.dataSources.pageData = undefined;
                websiteService.loadPage(state);
            }


            resolve();
        }),
        () => new Promise(async (resolve) => {

            if (state.dataSources.isClientSide) {
                const cssLinks = document.querySelectorAll('link[rel="stylesheet"]');

                const loadPromises = new Array<Promise<void>>();

                cssLinks.forEach((link: any) => {
                    if (link.sheet) {
                        // CSS is already loaded, resolve the promise immediately
                        loadPromises.push(Promise.resolve());
                    } else {
                        // Attach the load event listener and create a promise
                        const loadPromise = new Promise<void>((resolve, reject) => {
                            document.loadingManager.start();
                            if (link.sheet) {
                                document.loadingManager.end();
                            }
                            link.addEventListener('load', () => {

                                document.loadingManager.end();
                                resolve();
                            });

                            link.addEventListener('error', () => {
                                reject(new Error(`Failed to load stylesheet: ${link.href}`));
                            });
                        });
                        loadPromises.push(loadPromise);
                    }
                });

                Promise.all(loadPromises)
                    .then(() => {
                        document.loadingManager.end();
                    })
                    .catch(error => {
                        console.error(error);
                        // Handle error if needed, e.g., retry or notify the user
                        document.loadingManager.end(); // Optionally end loading even on error
                    });
            }
            if (state.dataSources.isClientSide && state.dataSources.initialClientRendering) {
                const allowedRoleConditions = state.dataSources.pageData.allowedRoles;
                const deniedRoleConditions = state.dataSources.pageData.deniedRoles;
                if (!document.targetSiteDocument || !document.targetSiteDocument.dataSources || !document.targetSiteDocument.dataSources.evaluateCondition) return;
                // Evaluate whether to show the component
                const allowed =
                    (!allowedRoleConditions || allowedRoleConditions.length === 0 || document.targetSiteDocument.dataSources.evaluateCondition(allowedRoleConditions)) &&
                    (!deniedRoleConditions || deniedRoleConditions.length === 0 || !document.targetSiteDocument.dataSources.evaluateCondition(deniedRoleConditions));

                //updateDateProperties(state.dataSources); to trigger rerender in client locale
                const myEvent = new CustomEvent('PageLoaded', {
                    detail: { /* Your custom data here */ }
                });
                document.dispatchEvent(myEvent);


                LoaderManager.end();

                if (!allowed) {
                    if (state.dataSources.isClientSide)
                        oauth.authorize();
                }
                resolve();
                return;
            }



            state.dataSources.pageData = await websiteService.getLoadedPageData();


            state.isNewPage = !state.dataSources.pageData;

            state.dataSources.websiteData = await websiteService.getLoadedWebsiteData();




            if (state.isNewPage) {
                state.dataSources.pageData = {

                };
            }




            if (!state.dataSources.pageData.browserTitle) {
                state.dataSources.pageData.browserTitle = state.dataSources.websiteData.displayName;
            }

            const allowedRoleConditions = state.dataSources.pageData.allowedRoles;
            const deniedRoleConditions = state.dataSources.pageData.deniedRoles;
            if (!document.targetSiteDocument || !document.targetSiteDocument.dataSources || !document.targetSiteDocument.dataSources.evaluateCondition) return;
            // Evaluate whether to show the component
            const allowed =
                (!allowedRoleConditions || allowedRoleConditions.length === 0 || document.targetSiteDocument.dataSources.evaluateCondition(allowedRoleConditions)) &&
                (!deniedRoleConditions || deniedRoleConditions.length === 0 || !document.targetSiteDocument.dataSources.evaluateCondition(deniedRoleConditions));

            if (!allowed) {
                if (state.dataSources.isClientSide)
                    oauth.authorize();

                if (state.dataSources.isServerSide) {
                    const g = document.getElementById("app");
                    if (g) {
                        g.innerHTML = '';
                    }
                }
            }


            if (state.dataSources.pageData?.extraScripts && state.dataSources.pageData?.extraScripts.length > 0 && !state.dataSources.editorEnabled) {
                state.dataSources.pageData?.extraScripts.forEach((x: any, index: number) => {
                    const key = `extra-script-${index}`
                    if (!document.getElementById(key)) {

                        const script = document.targetSiteDocument.createElement('script');
                        // Optional: Add a type attribute to the style element (not necessary in HTML5)

                        script.type = 'application/javascript';
                        script.src = `${x}?dontPreload=true`;
                        script.id = key;
                        document.targetSiteDocument.getElementById("app")?.appendChild(script);

                    }
                });
            }


            const myEvent = new CustomEvent('PageLoaded', {
                detail: { /* Your custom data here */ }
            });
            document.dispatchEvent(myEvent);
            if (state.dataSources.isClientSide && !state.dataSources.initialClientRendering) {


                const myEvent = new CustomEvent('ready', {
                    detail: { /* Your custom data here */ }
                });
                document.dispatchEvent(myEvent);




            }

            LoaderManager.end();
            resolve();
        })
    );



});


