import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';

class ButtonPlugin implements IGrapesJsPlugin {
    /**
     *
     */
    constructor() {

    }
    
    configureAlways(state: IWebsiteState): void {
    }

    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {
        return new Promise(resolve => {
           //data-action-override
            // Resolve the promise with the editor object after configuration

            ["button"].forEach((x: string) => {
                editor.DomComponents.addType(x, {
                    model: {
                        defaults: {
                            ...editor.DomComponents.getType(x)?.model.prototype.defaults,
                            traits: [
                                ...editor.DomComponents.getType(x)?.model.prototype.defaults?.traits  || [],
                                {
                                    type: 'text',
                                    label: 'Trigger data source event',
                                    name: 'data-action-override',
                                    placeholder: 'Check you json data bindings and choose an event'
                                },
                            ]
                        }
                    }
                });
            });
            resolve(editor);
        });
    }
}

export default ButtonPlugin;