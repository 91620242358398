import OAuth from '../auth/oauth';

class HttpClient {
    /**
     *
     */
    constructor(private oAuth?: OAuth) {

    }

    // Example GET request using fetch
    async fetchData(url: string, queryObj: any = undefined, asString: boolean = false): Promise<any> {
        let usedUrl = url;
        let headers = {};
        
        const token = await this.oAuth?.getToken();
        if (token) {
            headers = {
                ...headers, ...{
                    'Authorization': `Bearer ${token}`, // Use the token in the Authorization header
                }
            }
        }

        if (queryObj && Object.keys(queryObj).length) {
            // Initialize an array to hold the query parameters
            const queryParams = [];
        
            // Iterate over the keys and values of the queryObj
            for (const key in queryObj) {
                if (queryObj.hasOwnProperty(key)) {
                    const values = queryObj[key];
                    if (Array.isArray(values)) {
                        // If the value is an array, add each element as a separate query parameter
                        values.forEach(value => {
                            queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
                        });
                    } else {
                        // If the value is not an array, add it as a single query parameter
                        queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(values)}`);
                    }
                }
            }
        
            // Join the query parameters with '&'
            const queryString = queryParams.join('&');
        
            // Append the query string to the URL
            if (usedUrl.includes("?")) {
                usedUrl += `&${queryString}`;
            } else {
                usedUrl += `?${queryString}`;
            }
        }
        
        const response = await fetch(usedUrl, {
            headers
        });
        if (response.status === 401) {
            return undefined;
        }
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        if (response.status === 204) {
            return undefined;
        }const contentType = response.headers.get("content-type");

        let res;
        if (contentType && contentType.includes("application/json")) {
            const data = await response.json(); // Parse JSON response
            res = this.convertDates(data);
        } else {
            const text = await response.text(); // Return as text if not JSON
            res = text;
        }
        
        return res;
    }

    async delete(url: string, queryObj: any = undefined): Promise<any> {
        let usedUrl = url;
        let headers = {};
        
        const token = await this.oAuth?.getToken();
        if (token) {
            headers = {
                ...headers, ...{
                    'Authorization': `Bearer ${token}`, // Use the token in the Authorization header
                }
            }
        }

        if (queryObj && Object.keys(queryObj).length) {
            // Convert the queryObj to a query string
            const queryString = new URLSearchParams(this.removeEmptyArrays(queryObj)).toString();
            // Append the query string to the URL
            if(usedUrl.includes("?")){

                usedUrl += `&${queryString}`;
            }else{
                
                usedUrl += `?${queryString}`;
            }
        }
        const response = await fetch(usedUrl, {
            headers,
            method: 'DELETE'
        });
        
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        
    };
    
    // Helper function to detect and convert ISO date strings to Date objects
    convertDates(obj:any):any {
        if (typeof obj === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d+)?(Z|[+-]\d{2}:\d{2})?$/.test(obj)) {
            return new Date(obj);
        } else if (Array.isArray(obj)) {
            return obj.map(item => this.convertDates(item));
        } else if (typeof obj === 'object' && obj !== null) {
            for (const key in obj) {
                obj[key] = this.convertDates(obj[key]);
            }
        }
        return obj;
    }
    private removeEmptyArrays(obj: any) {
        const cleanedObj = {} as any;
        Object.keys(obj).forEach(key => {
            // Check if the property is an array and not empty
            if (!Array.isArray(obj[key]) || obj[key].length > 0) {
                cleanedObj[key] = obj[key];
            }
            // If it's not an array, just copy the value
            else if (!Array.isArray(obj[key])) {
                cleanedObj[key] = obj[key];
            }
        });
        return cleanedObj;
    }
    // Example POST request using fetch
    async postData(url: string, data: any, asString: boolean = false): Promise<any> {
        const token = await this.oAuth?.getToken();
        let headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        };
        if (token) {
            headers = {
                ...headers, ...{
                    'Authorization': `Bearer ${token}`, // Use the token in the Authorization header
                }
            }
        }
        const response = await fetch(url, {
            method: 'POST',
            headers,
            body: asString ? data : JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        if (response.status === 404) {
            return undefined;
        }
        return asString ? await response.text() :  await response.json();
    };

    async postFormData(url: string, data: FormData): Promise<any> {
        const token = await this.oAuth?.getToken();
        let headers = {};
        if (token) {
            headers = {
                ...headers,
                'Authorization': `Bearer ${token}`, // Use the token in the Authorization header
            };
        }

        const response = await fetch(url, {
            method: 'POST',
            headers, // No Content-Type header
            body: data, // Send FormData directly
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    };
    async postFormDataObjectJsonResult(url: string, data: any): Promise<any> {
        var formData = new FormData();
        Object.keys(data).forEach((x, y, z) => {
            formData.append(x, data[x]);
        })
        const token = await this.oAuth?.getToken();
        let headers = {};
        if (token) {
            headers = {
                ...headers,
                'Authorization': `Bearer ${token}`, // Use the token in the Authorization header
            };
        }

        const response = await fetch(url, {
            method: 'POST',
            headers, // No Content-Type header
            body: formData, // Send FormData directly
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    };

    async postFormDataObject(url: string, data: any): Promise<boolean> {
        var formData = new FormData();
        Object.keys(data).forEach((x, y, z) => {
            if (Array.isArray(data[x])) {
                data[x].forEach((item: any, index: number) => {
                    Object.keys(item).forEach(subKey => {
                        formData.append(`${x}[${index}].${subKey}`, item[subKey]);
                    });
                });
            } else {
                formData.append(x, data[x]);
            }
        })
        const token = await this.oAuth?.getToken();
        let headers = {};
        if (token) {
            headers = {
                ...headers,
                'Authorization': `Bearer ${token}`, // Use the token in the Authorization header
            };
        }

        const response = await fetch(url, {
            method: 'POST',
            headers, // No Content-Type header
            body: formData, // Send FormData directly
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        if (response.status === 404) {
            return false;
        }

        return true;
    };
}

export default HttpClient;