import { Context } from 'page';
import page from 'page';
import OAuth from '../auth/oauth';
import { loadComponent } from './dynamicLoader';
import { LoaderManager } from './loader/loader';
import { IWebsiteState } from '../models/website-state.model';
import GrapesJs from '../grapesjs/grapesjs';
import { configureState } from '../state/configureState';
import SiteDataLoadingService from '../page/index/website.service';

export async function setupRoutes(oauth: OAuth, state: IWebsiteState, grapesJs: GrapesJs, websiteService: SiteDataLoadingService, beforeRender: () => Promise<void>, afterRender: () => Promise<void>) {
    // Define the middleware for secured routes


    page('/', (ctx: PageJS.Context) => {
        handleComplexRoute(ctx, state, grapesJs, websiteService, beforeRender, afterRender, oauth, () => {
            if (state.dataSources.isClientSide) {

                state.dataSources.initialClientRendering = false;
                return redirectToLang(ctx, state)
            }
            return redirectToLang(ctx, state);
            return new Promise<void>(resolve => {

                LoaderManager.end();
                resolve()
            });

        })

    });
    page('/auth/callback', (ctx: PageJS.Context) => {
        handleComplexRoute(ctx, state, grapesJs, websiteService, beforeRender, afterRender, oauth, () => {
            LoaderManager.start();

            if (state.dataSources.isClientSide) {

                return oauth.handleCallback().then(async () => {

                    const user = await oauth.getUserFromToken();
                    state.dataSources.user = user;

                    const loginRedirect = localStorage.getItem("login-redirect") as string;

                    state.dataSources.initialClientRendering = false;
                    if (loginRedirect && loginRedirect != '/login') {

                        localStorage.removeItem("login-redirect")
                        //await page.redirect(loginRedirect);
                        location.href = loginRedirect;
                        return;
                    }
                    await redirectToLang(ctx, state);
                    return;
                }
                ).then(() =>
                    LoaderManager.end());
            }

            return new Promise<void>(resolve => {

                LoaderManager.end();
                resolve()
            });

        })

    });


    page('/auth/callbackLogout', (ctx: PageJS.Context) => {
        handleComplexRoute(ctx, state, grapesJs, websiteService, beforeRender, afterRender, oauth, () => {
            LoaderManager.start();

            if (state.dataSources.isClientSide) {

                return oauth.logoutCallback().then(async () => {

                    location.href = '/';
                    return;
                }
                ).then(() =>
                    LoaderManager.end());
            }

            return new Promise<void>(resolve => {

                LoaderManager.end();
                resolve()
            });

        })

    });
    page('/auth/logout', (ctx: PageJS.Context) => {
        handleComplexRoute(ctx, state, grapesJs, websiteService, beforeRender, afterRender, oauth, () => {
            LoaderManager.start();

            if (state.dataSources.isClientSide) {

                return oauth.logout();
            }

            return new Promise<void>(resolve => {

                LoaderManager.end();
                resolve()
            });

        })

    });
    page('/auth/silentRefresh', (ctx: PageJS.Context) => {
        handleComplexRoute(ctx, state, grapesJs, websiteService, beforeRender, afterRender, oauth, () => {
            LoaderManager.start();

            if (state.dataSources.isClientSide) {

                return oauth.silentLogin();
            }

            return new Promise<void>(resolve => {

                LoaderManager.end();
                resolve()
            });

        })

    });
    page('/auth/silentRefreshCallback', (ctx: PageJS.Context) => {
        handleComplexRoute(ctx, state, grapesJs, websiteService, beforeRender, afterRender, oauth, () => {
            LoaderManager.start();

            if (state.dataSources.isClientSide) {

                return oauth.handleSilentCallback();
            }

            return new Promise<void>(resolve => {

                LoaderManager.end();
                resolve()
            });

        })

    });
    page('/login', async (ctx: PageJS.Context) => {
        handleComplexRoute(ctx, state, grapesJs, websiteService, beforeRender, afterRender, oauth, () => {
            if (state.dataSources.isClientSide) {
                LoaderManager.start();

                return oauth.getUserFromToken().then(async x => {
                    if (!x) {
                        await oauth.authorize()
                    } else {
                        state.dataSources.initialClientRendering = false;
                        location.href = '/';
                    }
                })
            }

            return new Promise<void>(resolve => {

                LoaderManager.end();
                resolve();
            });

        })

    })


    page('/logout', async (ctx: PageJS.Context) => {
        handleComplexRoute(ctx, state, grapesJs, websiteService, beforeRender, afterRender, oauth, () => {
            if (state.dataSources.isClientSide) {
                LoaderManager.start();
                return oauth.getUserFromToken().then(async x => {
                    if (!x) {
                        location.href = "/";
                        return;
                    } else {

                        await oauth.logout();
                        return;
                    }
                })
            }

            return new Promise<void>(resolve => {

                LoaderManager.end();
                resolve();
            });

        })

    });
    page('/:lang/*', (ctx: PageJS.Context) => handleComplexRoute(ctx, state, grapesJs, websiteService, beforeRender, afterRender, oauth, async () => {

        if (!state.dataSources.initialClientRendering || state.dataSources.isServerSide) {

            await replaceAndFetchComponents(state.queryStrings, document, state, websiteService)

        }

        return new Promise<void>(resolve => resolve());
    }));

    page.start();
}
function splitPath(path: string) {
    const delimiterIndex = path.indexOf('/');
    if (delimiterIndex === -1) {
        // No delimiter found, entire path is the first segment
        return { firstSegment: path, restOfPath: '' };
    } else {
        const firstSegment = path.substring(0, delimiterIndex);
        const restOfPath = path.substring(delimiterIndex + 1);
        return { firstSegment, restOfPath };
    }
}
async function handleComplexRoute(ctx: PageJS.Context, state: IWebsiteState, grapesJs: GrapesJs, websiteService: SiteDataLoadingService, beforeRender: () => Promise<void>, afterRender: () => Promise<void>, oAuth: OAuth, action: () => Promise<void>) {

    let prom: any = undefined;
    let path = ctx.pathname ? ctx.pathname.substring(1) : '';
    let dictionary: any = {};

    if (path) {
        path = path.substring(path.indexOf('/'));
        path = path.replace(/\/$/, "");
        let splittedPath = ctx.path.split('?');

        let qPath = splittedPath[1];

        if (qPath) {
            const segments = qPath.split('#')[0].split("&"); // Split by hash segments

            for (let i = 0; i < segments.length; i++) {
                const segment = segments[i].split("=");
                if (dictionary[segment[0]]) {
                    dictionary[segment[0]] = [...dictionary[segment[0]], segment[1]];
                } else {

                    dictionary[segment[0]] = segment[1];
                }
            }
        }
    }

    state.currentPath = path.replace(/\/+$/, "");
    state.dataSources.currentPath = path.replace(/\/+$/, "");
    state.previousFullPathWithQuery = state.fullPathWithQuery;
    state.fullPathWithQuery = ctx.pathname;

    state.fullPath = ctx.pathname.split("?")[0];
    const originalPath = (ctx.pathname ? ctx.pathname.substring(1) : '');
    state.language = originalPath.substring(0, originalPath.indexOf('/'));

    state.dataSources.editorEnabled = dictionary['editorEnabled'];
    state.queryStrings = dictionary;
    state.dataSources.queryStrings = dictionary;
    await beforeRender();
    prom = action();


    if (prom) {
        await prom;
    }

    let grapeInitialized = false;
    if (state.dataSources.editorEnabled && state.dataSources.isClientSide) {

        const user = await oAuth.getUserFromToken();
        if (user && user.isAdmin) {
            grapeInitialized = true;

            await grapesJs.initializeGrapesJs(prom);




        }
        else if (!user) {
            await oAuth.authorize();
        }
    }

    if (prom) {
        await prom;
    }


    await afterRender();



    if (state.dataSources.initialClientRendering && state.dataSources.isClientSide) {
        state.dataSources.initialClientRendering = false;

    }
    // Update page content or perform other actions based on the parsed segments
    //document.getElementById('content')?.textContent = 'Parsed URL: ' + segments.join(', ');

}

async function replaceAndFetchComponents(dictionary: any, root: any, state: IWebsiteState, websiteService: SiteDataLoadingService): Promise<void> {
    const elementsWithRoutePath = root.querySelectorAll('[router]');
    const prom = new Promise<void>((async resolve => {
        for (const element of Array.from(elementsWithRoutePath)) {
            if (element instanceof HTMLElement) {
                const path = element.getAttribute('current-route');
                await loadComponent(element, websiteService, path, state);
                element.setAttribute('current-route', state.currentPath);
                //await replaceAndFetchComponents(dictionary, element, state);
            }
        };
        resolve();
    }));
    return prom;
}

async function redirectToLang(ctx: Context, state: IWebsiteState): Promise<void> {
    return new Promise<void>(async (resolve) => {
        let userLang = navigator.language;
        userLang = userLang.split('-')[0];
        const siteData = state.dataSources.websiteData;
        const redirect = `/${siteData?.defaultLang || userLang}/${siteData?.rootPath || 'home'}`;
        if (state.dataSources.isClientSide) {
            // If user visits '/', redirect to '/:lang/home'

            page.redirect(redirect);

        }
        if (state.dataSources.isServerSide) {
            document.getElementById('app')?.setAttribute('redirect', redirect);
        }
        resolve();
    })
}


// Apply the middleware to all routes
//page('*', globalMiddleware);
// Define your route handlers, which now have access to the `oauth` instance if needed
// function index() {
//     loadComponent('index');
// }

// function login() {
//     loadComponent('login');
// }

// function dashboard() {
//     loadComponent('dashboard');
// }

// function profile() {
//     loadComponent('profile');
// }
