import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';
export const componentTimestamps: Map<string, Date> = new Map<string, Date>();
export const compenentFetchedName: Map<string, string> = new Map<string, string>();
class TrackModifyTimestamps implements IGrapesJsPlugin {
   

  constructor() {
  }

  configureAlways(state: IWebsiteState): void {
    // Always configure logic here if needed
  }

  configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {
    return new Promise((resolve) => {
      // Listen to component update events
      editor.on('component:update', (component) => {
        if(!component || !component.getAttribute || !component.getAttribute('data-fetched-component')) return;
        const componentId = component.getId();

        // Update the timestamp for the component
        componentTimestamps.set(componentId, new Date());
        compenentFetchedName.set(componentId, component.getAttribute('data-fetched-component'));

        //console.log(`Component ${componentId} updated at ${componentTimestamps.get(componentId)}`);
      });

      // Optionally, add logic to display or interact with timestamps in the UI
      this.addTimestampDisplayToUI(editor);

      resolve(editor);
    });
  }

  private addTimestampDisplayToUI(editor: Editor): void {
    const pn = editor.Panels;

    pn.addButton('options', {
      id: 'show-timestamps',
      className: 'fa fa-clock',
      command: () => this.showTimestamps(editor),
      attributes: { title: 'Show Component Timestamps' },
    });
  }

  private showTimestamps(editor: Editor): void {
    const components = editor.DomComponents.getComponents();
    const timestamps = Array.from(componentTimestamps.entries())
      .map(([id, timestamp]) => `${id}: ${timestamp.toISOString()}`)
      .join('\n');

    alert(`Component Timestamps:\n${timestamps}`);
  }
}

export default TrackModifyTimestamps;
