import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';
import { AiService } from '../../ai/file.service';

class FilePreviewPlugin implements IGrapesJsPlugin {
    /**
     *
     */
    constructor() {

    }

    configureAlways(state: IWebsiteState): void {
        
            if(state.dataSources.isServerSide) return;
            
            
            document.addEventListener('click', function (event:any) {
                // Check if the clicked element has the required data attributes
                const element = event.target.closest('[data-file-url][data-file-content-type][data-target-element-id]');
                if (!element) return;
            
                const fileUrl = element.getAttribute('data-file-url');
                const contentType = element.getAttribute('data-file-content-type');
                const elTargetId = element.getAttribute('data-target-element-id');
                const popup = document.createElement('div');
                const html = `<embed src="${fileUrl}" type="${contentType}" style="width: 100%; height: 800px;"></embed>`;
            
                if (elTargetId) {
                    const tEl = document.getElementById(elTargetId);
                    if (!tEl) return;
            
                    let embed = tEl.querySelector("embed");
                    if (!embed) {
                        tEl.innerHTML = html;
                        embed = tEl.querySelector("embed");
                        embed?.setAttribute("data-expanded", "true");
                        return;
                    }
            
                    if (embed?.getAttribute("data-expanded") === "true") {
                        embed.style.display = "none";
                        embed?.setAttribute("data-collapsed", "true");
                        embed?.removeAttribute("data-expanded");
                        return;
                    }
            
                    if (embed?.getAttribute("data-collapsed") === "true") {
                        embed.style.display = "block";
                        embed?.setAttribute("data-expanded", "true");
                        embed?.removeAttribute("data-collapsed");
                        return;
                    }
                }
            
                popup.style.position = 'fixed';
                popup.style.top = '50%';
                popup.style.left = '50%';
                popup.style.transform = 'translate(-50%, -50%)';
                popup.style.backgroundColor = 'white';
                popup.style.border = '1px solid #ccc';
                popup.style.padding = '10px';
                popup.style.zIndex = '1000';
                popup.innerHTML = `
                    <div style="margin-top: 10px; margin-bottom: 10px; width: 100%; display: flex; justify-content: space-between;">
                        <a href="${fileUrl}" download>Download</a> 
                        <a href="#" id="close-popup">Close</a>
                    </div>
                    ${html}
                `;
                document.body.appendChild(popup);
                document.getElementById('close-popup')?.addEventListener('click', function (e) {
                    e.preventDefault();
                    document.body.removeChild(popup);
                });
            });
            
            

    }

    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {
        return new Promise(resolve => {
            editor.DomComponents.addType("file-preview", {
                isComponent: (el: any) => {
                    if (!el.getAttribute) return false;
                    return el?.getAttribute("data-behavior")?.toLocaleLowerCase() === 'file-preview';
                },
                model: {

                    attributes: {
                        "clear-ds-on-save": true
                    },
                    defaults: {
                        ...(editor.DomComponents.getType("file-preview") as any)?.model?.prototype?.defaults ?? [],

                        traits: [
                            {
                                type: 'text',
                                label: 'Bind to model',
                                name: 'binded-model',
                                placeholder: 'Enter data source identifier...'
                            },

                            ...(editor.DomComponents.getType("file-preview") as any)?.model?.prototype?.defaults?.traits ?? []

                        ],
                        



                    }

                }
            });
            // Resolve the promise with the editor object after configuration
            resolve(editor);
        });
    }
}

export default FilePreviewPlugin;