import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';
import HttpClient from '../../utils/httpclient.service';
import { AiService } from '../../ai/file.service';

class AiPlugin implements IGrapesJsPlugin {
    /**
     *
     */
    constructor( private aiService: AiService) {

    }

    configureAlways(state: IWebsiteState): void {
    }

    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {
        return new Promise(resolve => {
            //data-action-override
            // Resolve the promise with the editor object after configuration

            // Add the AI button to the component toolbar
            editor.on('component:selected', () => {
                const selected = editor.getSelected();
                if (selected && selected.get('toolbar')?.filter(x => x.command === 'open-ai-popup').length === 0) {
                    selected.set('toolbar', [
                        ...selected.get('toolbar') as any,
                        {
                            attributes: { class: 'fa fa-brain' },
                            command: 'open-ai-popup',
                            label: 'AI',
                        }
                    ]);
                }
            });
            const self = this;
            // Define the command to open the AI popup
            editor.Commands.add('open-ai-popup', {
                async run(editor, sender) {
                    const selected = editor.getSelected();
                    if (selected) {
                        const userInput = prompt("Beschrijf wat u precies wilt: ");
                        if (userInput) {
                            doc.loadingManager.start();
                            const input = state.dataSources.websiteData.defaultAiInputHtml.replace('{{html}}', selected.getEl()?.innerHTML).replace('{{websiteDescription}}', state.dataSources.websiteData.description || '').replace('{{question}}',userInput);
                            
                            selected.components(await self.aiService.getHtml$(input));
                            
                            doc.loadingManager.end();
                        }
                    } else {
                        alert("No component selected.");
                    }
                }
            });
            resolve(editor);
        });
    }
}

export default AiPlugin;