import { IWebsiteState } from '../../models/website-state.model';
import { dateReviver } from '../deep-proxy.function';

require('./loader.css');


class Loader {
  private loaderWrapper: HTMLElement | null;
  private loadCount: number = 0;
  private defaultFadeOutTime: number = 500;

  constructor() {


    this.loaderWrapper = document.getElementById('loader-wrapper');

    // Initialize the loader in a hidden state
    if (this.loaderWrapper) {
      this.loaderWrapper.style.opacity = '0';
      this.loaderWrapper.hidden = true;
      this.loaderWrapper.style.display = "none";
    }
  }

  public start(): void {
    // if (document.dataSources.isClientSide && document.dataSources.initialClientRendering)
    //   return;

    this.loadCount++;
    if (this.loadCount === 1) {
      // Only show the loader if it's the first load starting
      this.show();
    }
  }

  public end(): void {

    if (this.loadCount > 0) this.loadCount--;
    if (this.loadCount === 0) {
      // Only hide the loader if all loads have ended
      this.hide();
    }
  }

  private show(): void {
    if (this.loaderWrapper) {
      const loadedFlag = document.getElementById('loaded');
      if (loadedFlag) {
        loadedFlag.remove();
      }
      this.loaderWrapper.hidden = false;

      this.loaderWrapper!.style.opacity = '1';
      this.loaderWrapper!.style.display = "flex";
    }
  }

  private hide(): void {

    if (this.loaderWrapper) {
      const loadedFlag = document.getElementById('loaded');
      if (!loadedFlag) {



        if (document.dataSources.isServerSide) {

          let data = JSON.parse(JSON.stringify({ ...document.state }));
          if (data.dataSources.pageData) {
            data.dataSources.pageData.html = undefined;
          }
          const sr = JSON.stringify(data);
          document.targetSiteDocument.getElementById('data')?.setAttribute('state-data', sr)
          document.getElementById('data')?.setAttribute('state-data', sr);
        }


      }


      const elementsWithXorY = document.querySelectorAll('[allowed-roles], [denied-roles]');

      // Example usage
      elementsWithXorY.forEach((element: any) => {
        if (!document.dataSources.editorEnabled)
          document.dataSources.evaluateRoles(element); // Do something with each element
      });
     

      if (document.dataSources.isServerSide) {
        this.loaderWrapper.style.background = "#FFFFFF";
        this.loaderWrapper.style.opacity = '1';
        this.loaderWrapper!.hidden = false;
        this.loaderWrapper!.style.display = "flex";
      }else{
        this.loaderWrapper.style.opacity = '0';
        this.loaderWrapper!.hidden = true;
        this.loaderWrapper!.style.display = "none";
        
        this.loaderWrapper.style.background = "none";
      }
      const el = document.getElementById('data');
      const htmlBlock = document.createElement('div');
      htmlBlock.setAttribute('id', 'loaded');
      el?.appendChild(htmlBlock);

    }
  }
}

export const LoaderManager = new Loader();