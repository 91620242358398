import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';

class SecurityPlugin implements IGrapesJsPlugin {
    /**
     *
     */
    constructor() {

    }

    configureAlways(state: IWebsiteState): void {
        state.dataSources.componentWithDataSourceUpdateViaGrape.text = true;
        
        document.dataSources.evaluateStringCondition = (condition: string) => {
            // Initialize variables to hold the parts of the condition
            let operand, operator, value;

            // Define the operators you want to handle
            const operators = ['==', '!='];

            
            // Find the operator in the condition and split it
            for (const op of operators) {
                if (condition.includes(op)) {
                    operator = op;
                    [operand, value] = condition.split(op).map(s => s.trim()); // Split and trim whitespace
                    break; // Exit the loop once the operator is found
                }
            }

            // Ensure all parts of the condition have been identified
            if (!operand || !operator || value === undefined) {
                if (condition.startsWith('{{')) {
                    const t = document.selectTargetBasedOnPath(document.dataSources, condition.replace('{{', '').replace('}}', ''));
                    return t.targetObj ? t.targetObj[t.key] : undefined;
                } return condition;
            }
            let operandActual;

            if (operand.startsWith('{{')) {
                const t = document.selectTargetBasedOnPath(document.dataSources, operand.replace('{{', '').replace('}}', ''));
                operandActual = t.targetObj ? t.targetObj[t.key] : undefined;
            } else operandActual = operand;
            let valueActual;
            if (value.startsWith('{{')) {
                const t = document.selectTargetBasedOnPath(document.dataSources, value.replace('{{', '').replace('}}', ''));
                valueActual = t.targetObj ? t.targetObj[t.key] : undefined;
            } else valueActual = value;

            // Evaluate the condition based on the operator
            switch (operator) {
                case '==':
                    return Array.isArray(operandActual) ? operandActual.includes(valueActual) : (operandActual === valueActual);
                case '!=':
                    return Array.isArray(operandActual) ? !operandActual.includes(valueActual) : (operandActual !== valueActual);
                default:
                    // This should never be reached if all operators are handled in the loop above
                    console.error("Unhandled operator:", operator);
                    return false;
            }
        }
        document.dataSources.evaluateCondition = (condition: any) => {
            if (typeof condition === 'string') {
                return document.dataSources.evaluateStringCondition(condition) //userRoles.includes(condition);
            } else if (Array.isArray(condition)) {
                // OR condition
                return condition.some((subCondition: any) => document.dataSources.evaluateCondition(subCondition));
            } else if (typeof condition === 'object' && condition !== null) {
                // Nested conditions (AND/OR)
                const operator = Object.keys(condition)[0];
                const operands = condition[operator];
                if (operator === 'and') {
                    return operands.every((subCondition: any) => document.dataSources.evaluateCondition(subCondition));
                } else if (operator === 'or') {
                    return operands.some((subCondition: any) => document.dataSources.evaluateCondition(subCondition));
                }
            }
            return false;
        }



        const element = this;
        document.dataSources.evaluateRoles = (element: any ) =>  {
            // Check if allowed-roles and denied-roles attributes exist
            if (!element.getAttribute('allowed-roles') && !element.getAttribute('denied-roles')) {
                return;
            }

            // Parse role conditions
            const allowedRoleConditions = element.getAttribute('allowed-roles') ? JSON.parse(element.getAttribute('allowed-roles')) : null;
            const deniedRoleConditions = element.getAttribute('denied-roles') ? JSON.parse(element.getAttribute('denied-roles')) : null;
            if (!document.targetSiteDocument || !document.targetSiteDocument.dataSources || !document.targetSiteDocument.dataSources.evaluateCondition) return;
            // Evaluate whether to show the component
            const showComponent =
                (!allowedRoleConditions || allowedRoleConditions.length === 0 || document.targetSiteDocument.dataSources.evaluateCondition(allowedRoleConditions)) &&
                (!deniedRoleConditions || deniedRoleConditions.length === 0 || !document.targetSiteDocument.dataSources.evaluateCondition(deniedRoleConditions));


            if (!showComponent) {

            }
            // Set display style based on showComponent
            element.style.display = showComponent ? '' : 'none';

        }

        document.addEventListener("DataSourceChanged", (e) => {
            if (document.dataSources.isServerSide)
                document.loadingManager.start();

            const elementsWithXorY = document.querySelectorAll('[allowed-roles], [denied-roles]');

            // Example usage
            elementsWithXorY.forEach((element:any) => {
                if(!document.dataSources.editorEnabled)
                document.dataSources.evaluateRoles(element); // Do something with each element
            });

            if (document.dataSources.isServerSide)
                document.loadingManager.end();
        });

       

    }
    setupRoleBasedVisibility(conditions: any, element: HTMLElement) {
        // Example roles, you should obtain these from your application's user context
        const userRoles = ['user']; // Placeholder


    }
    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {
        const inn = this;
        return new Promise(resolve => {
            doc.dataSources.evaluateCondition = document.dataSources.evaluateCondition;
            doc.dataSources.evaluateStringCondition = document.dataSources.evaluateStringCondition;
            Object.keys(editor.DomComponents.componentTypes).forEach((type: any) => {
                const originalType = editor.DomComponents.componentTypes[type];
                if (!originalType) return; // Skip if the type is not defined

                const originalModel = originalType.model.prototype as any;
                const originalModelScript = originalModel.script;
                if (originalModelScript) {

                }
                // Assign the custom script to the model
                editor.DomComponents.addType(editor.DomComponents.componentTypes[type].id, {
                    model: {
                        defaults: {
                            ...originalModel.defaults,

                            traits: [
                                ...originalModel
                                    .defaults.traits,
                                {
                                    type: 'text',

                                    label: 'Allow Roles',
                                    name: 'allowed-roles',
                                    title: '"admin": The user must have the "admin" role.\n["admin", "editor"]: The user must have either the "admin" or "editor" role (OR condition). \n{"and": ["admin", ["editor", "contributor"]]}: The user must have the "admin" role AND either the "editor" or "contributor" role.'

                                },
                                {
                                    type: 'text',

                                    label: 'Deny roles',
                                    name: 'denied-roles',
                                    title: '"admin": The user must have the "admin" role.\n["admin", "editor"]: The user must have either the "admin" or "editor" role (OR condition). \n{"and": ["admin", ["editor", "contributor"]]}: The user must have the "admin" role AND either the "editor" or "contributor" role.'
                                }
                            ],
                        },



                    }
                });

            });



            // editor.DomComponents.addType("conditional", {
            //     isComponent: element => {
            //         return element.getAttribute && (element.getAttribute('allowed-roles') !== null || element.getAttribute('denied-roles') !== null);
            //     },
            //     model: {
            //         defaults: {
            //             script: function () {
            //                 // Call the additional script, also with correct context

            //                 const element = this;
            //                 function handle() {
            //                     // Check if allowed-roles and denied-roles attributes exist
            //                     if (!element.getAttribute('allowed-roles') && !element.getAttribute('denied-roles')) {
            //                         return;
            //                     }

            //                     // Parse role conditions
            //                     const allowedRoleConditions = element.getAttribute('allowed-roles') ? JSON.parse(element.getAttribute('allowed-roles')) : null;
            //                     const deniedRoleConditions = element.getAttribute('denied-roles') ? JSON.parse(element.getAttribute('denied-roles')) : null;
            //                     if(!document.targetSiteDocument || !document.targetSiteDocument.dataSources || !document.targetSiteDocument.dataSources.evaluateCondition) return;
            //                     // Evaluate whether to show the component
            //                     const showComponent =
            //                         (!allowedRoleConditions || allowedRoleConditions.length === 0 || document.targetSiteDocument.dataSources.evaluateCondition(allowedRoleConditions)) &&
            //                         (!deniedRoleConditions || deniedRoleConditions.length === 0 || !document.targetSiteDocument.dataSources.evaluateCondition(deniedRoleConditions));


            //                     if(!showComponent){

            //                     }
            //                     // Set display style based on showComponent
            //                     element.style.display = showComponent ? '' : 'none';

            //                 }

            //                 document.addEventListener("DataSourceChanged", (e) => {
            //                     if(document.dataSources.isServerSide)
            //                         document.loadingManager.start();
            //                     handle();

            //                     if(document.dataSources.isServerSide)
            //                         document.loadingManager.end();
            //                 })

            //             }
            //         },



            //     }
            // });
            // Resolve the promise with the editor object after configuration
            resolve(editor);
        });
    }
}


export default SecurityPlugin;

