import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';

class MultipleSelect implements IGrapesJsPlugin {
    /**
     *
     */
    constructor() {

    }

    configureAlways(state: IWebsiteState): void {
        const inputReplaceFunction = (el: any, value: any): void => {
            try{
                const options = el.options;
                el.value = value;
                let found = false;
                for (let i = 0; i < options.length; i++) {
    
                    if (options[i].value === value || (options[i].value === '' && !value)) {
                        el.selectedIndex = i;
                        options[i].setAttribute("selected", true);
                        found = true;
                    } else {
    
                        options[i].removeAttribute("selected");
                    }
                }
                // Optionally, clear selection if value not found
                if (!found)
                    el.selectedIndex = -1;
            }catch{
                
            }
            
        }

        const inputSelectFunction = (el: any) => {
            return el.value === '' ? undefined : el.value;
        };

        ["multiselect"].forEach((x: string) => {


            document.dataSources.setFunctions[x] = inputReplaceFunction;
            document.dataSources.getFunctions[x] = inputSelectFunction;
        });
    }
    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {



        return new Promise(resolve => {
            editor.DomComponents.addType("multiselect", {
                isComponent: (el: any) => {
                    if (el?.getAttribute) {
                        const va = el?.getAttribute('multiple') === 'multiple';

                        if (va) {

                            return true;
                        }

                        return el?.getAttribute('multiple') === 'multiple';
                    }

                    return false;
                },
                model: {

                    attributes: {
                        "clear-ds-on-save": true
                    },
                    defaults: {
                        ...(editor.DomComponents.getType("multiselect") as any)?.model?.prototype?.defaults ?? [],
                        attributes: {
                            'multiple': 'multiple'
                        },
                        traits: [
                            {
                                type: 'text',
                                label: 'Bind to model',
                                name: 'binded-model',
                                placeholder: 'Enter data source identifier...'
                            },

                            ...(editor.DomComponents.getType("multiselect") as any)?.model?.prototype?.defaults?.traits ?? []

                        ],
                        script: function () {

                            const element = this;

                            /* ----- Menu Cart Button Dropdown ----- */
                            

                        }


                    }
                }

            }
            );
            // Resolve the promise with the editor object after configuration
            resolve(editor);
        });
    }


}

export default MultipleSelect;