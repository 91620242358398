import { IWebsiteState } from '../models/website-state.model';
import SiteDataLoadingService from '../page/index/website.service';

// This module would dynamically load the JS and CSS for components
export async function loadComponent(target: HTMLElement, websiteService: SiteDataLoadingService, path: string | null, state: IWebsiteState): Promise<void> {
    return new Promise<void>(async (resolve) => {
        try {

            
            const component = await import(`../page/index/index`);
            await component.load(target, websiteService, state);
                

        } catch (error) {
            console.error(`Error loading page ${target}:`, error);
            // Further error handling or fallback logic
        } finally {
        }
        resolve();
    });
    // Dynamic import for JS



    // Conditional loading of CSS if necessary
    // if (componentName === 'dashboard') {
    //     await import('../styles/dashboard.css');
    // }
}