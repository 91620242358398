import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';
import SiteDataLoadingService from '../../page/index/website.service';

class DynamicComponentPlugin implements IGrapesJsPlugin {
    /**
     * Constructor
     */
    constructor(private websiteService: SiteDataLoadingService) {}

    configureAlways(state: IWebsiteState): void {}

    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {
        return new Promise((resolve) => {
            // Add the custom button to the component toolbar
            editor.on('component:selected', () => {
                const selected = editor.getSelected();
                if (selected && selected.get('toolbar')?.filter((x) => x.command === 'bind-component').length === 0) {
                    selected.set('toolbar', [
                        ...(selected.get('toolbar') as any),
                        {
                            attributes: { class: 'fa fa-cog' }, // Add an icon to the button
                            command: 'bind-component',
                            label: 'Action', // Label for the button
                        },
                    ]);
                }
            });
            var self = this;
            // Define the custom command
            editor.Commands.add('bind-component', {
                async run(editor, sender) {
                    const selected = editor.getSelected();
                    if (selected) {
                        var compName = prompt("Component name:");
                        
                        selected.setAttributes({'data-fetched-component': compName});
                        var comp = await self.websiteService.getComponent(compName);
                        if(comp && comp.id && confirm("Fetch content initially?")){
                            const parser = new DOMParser();
                            
                            const doc = parser.parseFromString(comp?.html, 'text/html');
                            
                            var el = selected.getEl();
                            if(el){

                                el.outerHTML = doc.body.innerHTML;

                                selected.components( el.innerHTML);
                            }
                        }else{

                        }
                    } else {
                        alert('No component selected.');
                    }
                },
            });

            resolve(editor);
        });
    }
}

export default DynamicComponentPlugin;
