import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';

class LinkPlugin implements IGrapesJsPlugin {
    /**
     *
     */
    constructor() {

    }
    configureAlways(state: IWebsiteState): void {
        state.dataSources.componentWithDataSourceUpdateViaGrape.link = true;
    }
    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {



        return new Promise(resolve => {
            // Assuming you have a wrapper ID for your GrapesJS editor

            editor.Canvas.getDocument().addEventListener('contextmenu', (ev: any) => {
                // Prevent the default context menu
                const target = ev.target;
                if (target.tagName.toUpperCase() === 'A') {
                    showCustomMenu(ev, target.href);
                } else {
                    const menu = editor.Canvas.getDocument().getElementById('grape-context-menu-link');
                    if (menu) {
                        menu.style.display = 'none';
                    }
                }
            });

            // Initialize a variable to hold the current href
            let currentHref = '';

            function showCustomMenu(e: any, linkHref: any) {
                e.preventDefault();
                // Update the currentHref with the new linkHref
                currentHref = linkHref;

                const doc = editor.Canvas.getDocument();
                let menu = doc.getElementById('grape-context-menu-link');
                if (!menu) {
                    menu = doc.createElement('div');
                    menu.id = 'grape-context-menu-link';
                    menu.innerHTML = '<ul><li style="list-style:none;" id="navigateLink">Navigate to URL</li></ul>';
                    doc.body.appendChild(menu);

                    // Add a click event listener to the menu using the currentHref
                    menu.addEventListener('click', function () {
                        const parsedUrl = new URL(currentHref);
                        if (parsedUrl.hostname === window.location.hostname) {
                            const params = new URLSearchParams(parsedUrl.search);
                            params.set('editorEnabled', 'true');
                            parsedUrl.search = params.toString();
                        }
                        window.location.href = parsedUrl.toString();
                    });
                }

                // Position and show the menu
                menu.style.left = `${e.pageX}px`;
                menu.style.top = `${e.pageY}px`;
                menu.style.display = 'block';
            }

            // Attach the event listener to the correct document context to ensure it can hide the menu
            editor.Canvas.getDocument().addEventListener('', function (event: any) {
                
            });
            editor.on('component:selected', () => {
                const menu = editor.Canvas.getDocument().getElementById('grape-context-menu-link');

                if (menu) {
                        menu.style.display = 'none';
                }
              });
            // Resolve the promise with the editor object after configuration
            resolve(editor);
        });
    }


}

export default LinkPlugin;