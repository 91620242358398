import { replaceStringWithDataSource } from './replaceStringWithDataSource';
export function dateReviver(key:any, value:any) {
  if (typeof value === 'string') {
      // This regular expression checks for date strings in ISO 8601 format
      const iso8601 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d+)?(Z|[+-]\d{2}:\d{2})?$/;
      if (iso8601.test(value)) {
          return new Date(value);
      }
  }
  return value; // return the value unchanged if not a date string
}
export function selectTargetBasedOnPath(dataSources: any, dataSourcePath: any) {
  if (!dataSourcePath) return {
    targetObj: {},
    key: ""
  };

  const pathParts = dataSourcePath.split('.');
  let targetObj = dataSources;

  for (let i = 0; i < pathParts.length - 1; i++) {
    const currentPart = pathParts[i];
    const isArray = Array.isArray(targetObj);
    const isNumericKey = !isNaN(currentPart) && parseInt(currentPart).toString() === currentPart;

    if (isArray && isNumericKey) {
      // Treat as an array index if the current target is an array and the key is numeric
      let newObj = targetObj[parseInt(currentPart, 10)];
      if (!newObj) {
        return {
          targetObj: undefined,
          key: ""
        };
        targetObj[parseInt(currentPart, 10)] = {};
      }

      targetObj = targetObj[parseInt(currentPart, 10)];
    } else if (!isArray && targetObj.hasOwnProperty(currentPart)) {
      // Treat as an object key
      targetObj = targetObj[currentPart];
    } else {
      // If neither, the path is invalid
      return {
        targetObj: undefined,
        key: ""
      };
    }

    // If targetObj is undefined or null, break early as the path is invalid
    if (targetObj == null) {
      return {
        targetObj: undefined,
        key: ""
      };
    }
  }

  const key = pathParts[pathParts.length - 1];
  return {
    targetObj,
    key
  };
}

document.selectTargetBasedOnPath = selectTargetBasedOnPath;

document.replaceStringWithDataSource = replaceStringWithDataSource;


export function createDeepProxyDataSourceListener(target: any, documentReference: any, currentPath: string = '') {

  return createDeepProxy(target, onObjectChange, documentReference, target, currentPath)
}
const proxyMap = new WeakMap();
export function createDeepProxy(target: any, handler: any, documentReference: any, datasource: any, currentPath = '') {

  for (let key in target) {
    if (typeof target[key] === 'object' && target[key] !== null && !(target[key] instanceof Date)) {
      const newPath = currentPath ? `${currentPath}.${key}` : key;
      target[key] = createDeepProxy(target[key], handler, documentReference, datasource, newPath);
    }
  }

  var proxy = new Proxy(target, handler(documentReference, datasource, currentPath));
  return proxy;
}
export function onObjectChange(documentReference: any, datasource: any, currentPath = '') {
  return {
    set: function (target: any, property: any, value: any) {
      let fullPath = currentPath ? `${currentPath}.${property}` : property;
     
      
      if (typeof value === 'object' && value !== null && !(value instanceof Date)) {
        
        value = createDeepProxy(JSON.parse(JSON.stringify(value), dateReviver), onObjectChange, documentReference, datasource, fullPath);
      }

      target[property] = value;

      const myEvent = new CustomEvent('DataSourceChanged', {
        detail: { /* Your custom data here */ }
      });
      document.dispatchEvent(myEvent);
      const filteredElements = Array.from(documentReference.targetSiteDocument.querySelectorAll(`[data-source="${fullPath}"]`)) as Array<Element>;
      const filteredElements2 = Array.from(documentReference.querySelectorAll(`[data-source="${fullPath}"]`)) as Array<Element>;
      [...filteredElements, ...filteredElements2, ].forEach(element => {
        setElementContentWithCursorPosition(element, value, fullPath, datasource);
      });
      const filteredElements3 = Array.from(documentReference.targetSiteDocument.querySelectorAll(`[data-source^="${fullPath}."]`)) as Array<Element>;
      const filteredElements4 = Array.from(documentReference.querySelectorAll(`[data-source^="${fullPath}."]`)) as Array<Element>;
      [...filteredElements3, ...filteredElements4].forEach(element => {
        setElementContentWithCursorPosition(element, value, fullPath, datasource);
      });
      
      // set objects
     


      // set attributes if changed
      if (fullPath.startsWith("attributes")) {
        var changedComp = fullPath.split('.')[1];
        Object.keys(value).forEach((x, y, z) => {
          const el = documentReference.getElementById(changedComp);
          if (el) {
            el.setAttribute(x, value[x])
            if (document.dataSources.attributesChanged) {
              document.dataSources.attributesChanged(el, value);
            }
          }

        })

      }

      return true;
    },
    ownKeys(target: any) {
      // Return all own property names of the target object
      return Reflect.ownKeys(target);
    },
    getPrototypeOf(target: any) {
      return target;
    },
    has(o: any, prop: any) {
      if (prop == Symbol.for("proxy")) return true;
      return prop in o;
    },
    
  };
}
function isDetectableProxy(obj: any) {
  if (obj === null || typeof obj != "object") return false;
  return Symbol.for("proxy") in obj;
}

export function setElementContentWithCursorPosition(el: Element, newValue: any, fullPath: string, originalDataSource: any) {
  const datasource = el.getAttribute('data-source')?.replace(fullPath, '') as string;
  let replaceValue = '';
  if (datasource === '') {
    replaceValue = newValue;
  } else {
    let itObj = newValue;
    let array = datasource.split('.');
    for (var i = 1; i < array.length; i++) {
      if (itObj) {
        itObj = itObj[array[i]]
      }

    }
    replaceValue = itObj;
  }
  var type = el.getAttribute('data-gjs-type') as string;
  const fu = (originalDataSource.setFunctions[type] || originalDataSource.setFunctions['default']);
  const get = (originalDataSource.getFunctions[type] || originalDataSource.getFunctions['default']);
  // if(get){
  //   if(get(el) === replaceValue){
  //     return;
  //   }
  // }
  if (fu)
    fu(el, replaceValue);
}

/*
* General utils for managing cookies in Typescript.
*/
export function setCookie(name: string, val: string) {
  const date = new Date();
  const value = val;

  // Set it expire in 7 days
  date.setTime(date.getTime() + (10*365 * 24 * 60 * 60 * 1000));

  // Set it
  document.cookie = name + "=" + value + "; path=/; expires="+date.toUTCString();
}

export function getCookie(name: string) {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=") as any;

  if (parts.length == 2) {
    return parts.pop().split(";").shift();
  }
}

export function deleteCookie(name: string) {
  const date = new Date();

  // Set it expire in -1 days
  date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

  // Set it
  document.cookie = name + "=; path=/";
}

export function listCookieKeys(prefix: string){

    const keys = [];
    const prefixLength = prefix.length;
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        if (cookie.indexOf(prefix) === 0) {
            keys.push(cookie.substring(prefixLength, cookie.indexOf('=')));
        }
    }
    return keys;
}