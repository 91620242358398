import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';

class CheckBoxDataSource implements IGrapesJsPlugin {
    /**
     *
     */
    constructor() {

    }
    private readonly types = ['checkbox'];

    configureAlways(state: IWebsiteState): void {
        const inputReplaceFunction = (el: any, value: any): void => {
            const options = el.options;
            if (el.getAttribute("array-value")) {
                if (!Array.isArray(value)) return;
                if (value.includes(el.getAttribute("array-value"))) {
                    el.checked = true;
                }
            } else {
                el.checked = value;
            }

        }

        const inputSelectFunction = (el: any) => {
            
            if (!el.getAttribute("array-value")) {
                return el.checked;
            }

            const selector = `input[type="checkbox"][data-source="${el.getAttribute("data-source")}"]`;
            const checkboxes = document.querySelectorAll(selector);

            // Initialize an array to hold the array-value attributes of checked checkboxes
            const checkedValues = [] as Array<any>;
            
            // Loop through the checkboxes
            checkboxes.forEach((checkbox: any) => {
                if (checkbox.checked) {
                    // If the checkbox is checked, get its array-value attribute and add it to the list
                    const arrayValue = checkbox.getAttribute("array-value");
                    checkedValues.push(arrayValue);
                }
            });

            // Return the list of array-value attributes from checked checkboxes
            return checkedValues;
        };

        this.types.forEach((x: string) => {


            document.dataSources.setFunctions[x] = inputReplaceFunction;
            document.dataSources.getFunctions[x] = inputSelectFunction;
        });
    }


    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {

        return new Promise(resolve => {
            this.types.forEach((x: string) => {
                editor.DomComponents.addType(x, {
                    model: {
                        defaults: {
                            ...editor.DomComponents.getType(x)?.model.prototype.defaults,
                            script: function () {

                                const element = this;

                                element.addEventListener('change', (event: any) => {
                                    
                                    const path = this.getAttribute("data-source");

                                    let {
                                        targetObj,
                                        key
                                    } = document.selectTargetBasedOnPath(document.dataSources, path);

                                    const checked = element.checked;
                                    if (!targetObj) {

                                        const segments = path.split('.');
                                        segments.pop(); // Remove the last element
                                        const parentPath = segments.join('.');
                                        let parent = document.selectTargetBasedOnPath(document.dataSources, parentPath);
                                        parent.targetObj[parent.key] = {};


                                        const newP = document.selectTargetBasedOnPath(document.dataSources, path);
                                        targetObj = newP.targetObj;
                                        key = newP.key;
                                    }

                                    const containVal = element.getAttribute("array-value");
                                    
                                    if (containVal) {
                                        if(!targetObj[key]) targetObj[key] = [];
                                        if (checked) {
                                            // Add the value to the array only if it does not already contain it
                                              if (!targetObj[key].includes(containVal)) {
                                                targetObj[key].push(containVal);
                                            }
                                        } else {
                                            // Remove the value from the array if it is present
                                            targetObj[key] = targetObj[key].filter((item: any) => item !== containVal);
                                        }

                                    } else {
                                        targetObj[key] = checked || '';
                                    }
                                });
                            },
                            traits: [
                                {
                                    type: 'text',
                                    label: 'Array value',
                                    name: 'array-value',
                                },
                                ...(editor.DomComponents.getType(x) as any)?.model?.prototype?.defaults?.traits ?? []
                            ]
                        }
                    }
                });
            });
            // Resolve the promise with the editor object after configuration
            resolve(editor);
        });
    }


}

export default CheckBoxDataSource