import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';

class FileSelect implements IGrapesJsPlugin {
    /**
     *
     */
    constructor() {

    }

    configureAlways(state: IWebsiteState): void {
        const inputReplaceFunction = (el: any, value: any): void => {
            return;
        }   

        const inputSelectFunction = (el: any) => {
            var dataSource = el.getAttribute("data-source");
            var t = document.selectTargetBasedOnPath(document.dataSources, dataSource);
            return t.targetObj[t.key];
        };

        ["fileselect"].forEach((x: string) => {


            document.dataSources.setFunctions[x] = inputReplaceFunction;
            document.dataSources.getFunctions[x] = inputSelectFunction;
        });
    }
    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {



        return new Promise(resolve => {
            editor.DomComponents.addType("fileselect", {
                isComponent: (el: any) => {
                    if ( el.tagName?.toLocaleLowerCase() === 'input' && el?.getAttribute && el?.getAttribute('type') === 'file') {
                       return true;
                    }

                    return false;
                },
                model: {

                    attributes: {
                        "clear-ds-on-save": true
                    },
                    defaults: {
                        ...(editor.DomComponents.getType("fileselect") as any)?.model?.prototype?.defaults ?? [],
                        attributes: {
                        },
                        traits: [
                            {
                                type: 'checkbox',
                                label: 'Multiple files',
                                name: 'file-multiple',
                                placeholder: 'Enter data source identifier...'
                            },
                            {
                                type: 'text',
                                label: 'Input model',
                                name: 'binded-input-model',
                                placeholder: 'Input model'
                            },

                            ...(editor.DomComponents.getType("fileselect") as any)?.model?.prototype?.defaults?.traits ?? []

                        ],
                        script: function () {

                            const element = this;

                            const bindedModel = this.getAttribute('data-source');
                            const bindedInputModel = this.getAttribute('binded-input-model');
                            
                            const multiple = this.getAttribute('file-multiple') === 'true';
                            const adminOnly = this.getAttribute('admin-only-upload') === 'true';
                            
                            element.addEventListener('change', (event:any) => {
                                
                                document.loadingManager.start();
                                const files = event.target.files;
                                if (files.length > 0) {
                                    const fileDataPromises = [];
                                    let eData = {} as any;
                                        if(bindedInputModel){
                                            const bim = document.selectTargetBasedOnPath(document.dataSources, bindedInputModel);
                                            eData = bim.targetObj[bim.key] || eData;
                                        }
                                    for (let i = 0; i < files.length; i++) {
                                        let f= files[i];
                                        if (!f.arrayBuffer) continue;
                                        
                                        const pr = f.arrayBuffer().then((bf:any) => {
                                            const data = {
                                                data: new Blob([bf]),
                                                fileName: f.name,
                                                isAdminOnly: false
                                            };

                                            return document.fileService.uploadFile$(Object.assign({}, eData, data), adminOnly, false);
                                        })
                                        
                                        fileDataPromises.push(pr);
                                    }
                                    
                                    Promise.all(fileDataPromises)
                                        .then(function(fileData) {
                                            let {
                                                targetObj,
                                                key
                                            } = document.selectTargetBasedOnPath(document.dataSources, bindedModel);
                            
                                            if (!targetObj) {
                                                const segments = bindedModel.split('.');
                                                segments.pop(); // Remove the last element
                                                const parentPath = segments.join('.');
                                                let parent = document.selectTargetBasedOnPath(document.dataSources, parentPath);
                                                parent.targetObj[parent.key] = {};
                            
                                                const newP = document.selectTargetBasedOnPath(document.dataSources, bindedModel);
                                                targetObj = newP.targetObj;
                                                key = newP.key;
                                            }
                            
                                            targetObj[key] = multiple ? fileData : fileData[0];
                                            document.loadingManager.end();
                                        })
                                        .catch(function(error) {
                                            console.error("Error uploading files:", error);
                                            document.loadingManager.end();
                                        });
                                } else {
                                    document.loadingManager.end();
                                }
                            });
                            
                            

                        }


                    }
                }

            }
            );
            // Resolve the promise with the editor object after configuration
            resolve(editor);
        });
    }


}

export default FileSelect;