import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';

class InputDataSource implements IGrapesJsPlugin {
    /**
     *
     */
    constructor() {

    }
    private readonly types = ['input', 'textarea'];

    configureAlways(state: IWebsiteState): void {
        const inputReplaceFunction = (el: any, value: any): void => {
            
            const newValue = value;
            if (el.value != (newValue || '')) {
                el.value = newValue || ''; 
                el.setAttribute('value', newValue || '');
            }
        }   

        const inputSelectFunction = (el: any) => {
            return el.value === '' ? undefined : el.value;
        };

        this.types.forEach((x: string) => {


            document.dataSources.setFunctions[x] = inputReplaceFunction;
            document.dataSources.getFunctions[x] = inputSelectFunction;
        });
    }


    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {

        return new Promise(resolve => {
            this.types.forEach((x: string) => {

                editor.DomComponents.addType(x, {
                    isComponent: (el: any) => {
                        if((editor.DomComponents.getType(x) ?? {} as any).isComponent(el)){
                            return el.getAttribute("type") !== "file";
                        }
    
                        return false;
                    },
                    model: {
                        defaults: {
                            ...editor.DomComponents.getType(x)?.model.prototype.defaults,
                            script: function () {

                                const element = this;

                                element.addEventListener('input', (event: any) => {

                                    const path = this.getAttribute("data-source");

                                    let {
                                        targetObj,
                                        key
                                    } = document.selectTargetBasedOnPath(document.dataSources, path);

                                    const newValue = event.target.value;
                                    if (!targetObj) {

                                        const segments = path.split('.');
                                        segments.pop(); // Remove the last element
                                        const parentPath =  segments.join('.');
                                        let parent = document.selectTargetBasedOnPath(document.dataSources, parentPath);
                                        parent.targetObj[parent.key] = {};


                                        const newP = document.selectTargetBasedOnPath(document.dataSources, path);
                                        targetObj = newP.targetObj;
                                        key = newP.key;
                                    }
                                    targetObj[key] = newValue || '';
                                });
                            }
                        }
                    }
                });
            });
            // Resolve the promise with the editor object after configuration
            resolve(editor);
        });
    }


}

export default InputDataSource