import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';
import { FileService } from '../../files/file.service';

class ItvanceFilesPlugin implements IGrapesJsPlugin {
    /**
     *
     */
    constructor(private fileService: FileService) {

    }

    configureAlways(state: IWebsiteState): void {
        
    }


    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {

        return new Promise(resolve => {
            
            // Resolve the promise with the editor object after configuration
            resolve(editor);
        });
    }


}

export default ItvanceFilesPlugin