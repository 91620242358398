import { IWebsiteState } from '../models/website-state.model';
import HttpClient from '../utils/httpclient.service';


export class AiService {
  constructor(
    private httpService: HttpClient,
    private websiteState: IWebsiteState) { }
  getBaseUrl(): string {

    
    return `https://${this.websiteState.dataSources.websiteData.tenantId}.cms${this.websiteState.dataSources.environmentUrl}.itvanceplatform.com`
  }


  getHtml$(originalContent: string): Promise<string> {
    return this.httpService.postData(`${this.getBaseUrl()}/Api/Ai/Web`, originalContent, true);
  }
}
