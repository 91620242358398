import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';

class TextPlugin implements IGrapesJsPlugin {
    /**
     *
     */
    constructor() {

    }
    
    configureAlways(state: IWebsiteState): void {
        state.dataSources.componentWithDataSourceUpdateViaGrape.text = true;
    }

    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {
        return new Promise(resolve => {
           
            // Resolve the promise with the editor object after configuration
            resolve(editor);
        });
    }
}

export default TextPlugin;