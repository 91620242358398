import { IWebsiteState } from '../models/website-state.model';
import { selectTargetBasedOnPath } from './deep-proxy.function';

export function replaceStringWithDataSource(input: string, dataSources: any, undefinedIfAnyUndefined = false) {
    // Regular expression to match {anythingHere}
    const regex = /{+([^}]+)}+/g;
    let anyNotfound = false;
    // Replace each match in the templateString
    var string =  input.replace(regex, (match, key) => {
        // Trying to access the deep property in data object
        // Splitting key by '.' allows accessing nested properties
        var dataObj = selectTargetBasedOnPath(dataSources, key);
        if(!dataObj.targetObj || !dataObj.targetObj[dataObj.key]) anyNotfound = true;
        return dataObj.targetObj ? dataObj.targetObj[dataObj.key] || '' : '';
    });

    return undefinedIfAnyUndefined && anyNotfound ? undefined : string;
}