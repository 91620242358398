
import { Editor, Trait } from 'grapesjs';
import { IGrapesJsPlugin } from '../models/grapesjs-plugin.model';
import { IWebsiteState } from '../models/website-state.model';
import DataSourceTraitsPlugin from './plugins/data-source-trait.plugin';
import NavPlugin from './plugins/nav.plugin';
import LinkPlugin from './plugins/link.plugin';
import TextPlugin from './plugins/text.plugin';
import InputDataSource from './plugins/input.data-source';
import SiteDataLoadingService from '../page/index/website.service';
import { FileService } from '../files/file.service';
import ItvanceFilesPlugin from './plugins/itvance-files.plugin';
import { LoaderManager } from '../utils/loader/loader';
import FormPlugin from './plugins/form.plugin';
import HttpClient from '../utils/httpclient.service';
import Recaptcha from './plugins/recaptcha.plugin';
import DataSourceListPlugin from './plugins/data-source-list.plugin';
import SecurityPlugin from './plugins/security.plugin';
import DataSourceFetcherPlugin from './plugins/data-source-fetcher.plugin';
import SelectDataSource from './plugins/select.data-source';
import ButtonPlugin from './plugins/button.plugin';
import CheckBoxDataSource from './plugins/checkbox.data-source';
import ImagePlugin from './plugins/image.plugin';
import GalleryPlugin from './plugins/gallery.plugin';
import MultipleSelect from './plugins/multi-select';
import FileSelect from './plugins/file-select.plugin';
import AiPlugin from './plugins/ai.plugin';
import { AiService } from '../ai/file.service';
import { styleManager } from './stylemanager';
import ArrowSlidingToolbar from './plugins/arrow-sliding-toolbar.plugin';
import FilePreviewPlugin from './plugins/file-preview.plugin';
import ClassRulePlugin, { compenentFetchedName, componentTimestamps } from './plugins/track-modifytimestamps.plugin';
import DynamicComponentPlugin from './plugins/dynamic-component.plugin';



class GrapesJs {

  private requireCss: any;
  private loadJs?: Promise<any>;
  private plugins: IGrapesJsPlugin[];
  constructor(private state: IWebsiteState, private websiteService: SiteDataLoadingService, private fileService: FileService, private httpClient: HttpClient) {
    this.plugins = [
      new DataSourceTraitsPlugin(),
      new ImagePlugin(),
      new ClassRulePlugin(),
      new GalleryPlugin(),
      new LinkPlugin(),
      new AiPlugin(new AiService(httpClient, state)),
      new TextPlugin(),
      new ButtonPlugin(),
      new InputDataSource(),
      new CheckBoxDataSource(),
      new ArrowSlidingToolbar(),
      new SelectDataSource(),
      new ItvanceFilesPlugin(this.fileService),
      new FormPlugin(this.httpClient),
      new Recaptcha(),
      new FilePreviewPlugin(),
      new MultipleSelect(),
      new FileSelect(),
      new DataSourceListPlugin(),
      new SecurityPlugin(),
      new DynamicComponentPlugin(websiteService),
      new DataSourceFetcherPlugin(this.httpClient),
    ];


    for (let i = 0; i < this.plugins.length; i++) {
      this.plugins[i].configureAlways(this.state);
    }

  }
  // Initiates the OAuth flow by redirecting to the OAuth provider's URL
  public async initializeGrapesJs(preloadTask: Promise<any>) {
    return new Promise(async resolve => {
      if (!this.requireCss) {

        this.requireCss = require('grapesjs/dist/css/grapes.min.css');
        await require('./../assets/styles/editor.css');
      }

      if (!this.loadJs) {

        this.loadJs = import('grapesjs');
      }

      await this.loadJs.then(async (grapesjs) => {
        // Initialize GrapeseJS here
        // Get 'id' from query string or default to 'app'
        if (preloadTask) {
          await preloadTask;
        }

        const globalCss = document.getElementById("global-css")?.getAttribute("href");;
        const globalScript = document.getElementById("global-script")?.getAttribute("src");


        const globalCssesLinks = [] as any;

        let cssIndex = 0;
        let globalCsses: any;
        do {

          globalCsses = document.getElementById(`global-css-${cssIndex}`)?.getAttribute("href");
          if (globalCsses) {

            globalCssesLinks.push(globalCsses)
            // Show borders by default
          }
          cssIndex++;
        } while (globalCsses)


        const globalScriptsSrcs = [] as any;
        let scriptIndex = 0;
        let globalScripts: any;
        do {

          globalScripts = document.getElementById(`global-script-${scriptIndex}`)?.getAttribute("src");
          if (globalScripts) {

            globalScriptsSrcs.push(globalScripts)
            // Show borders by default
          }
          scriptIndex++;
        } while (globalScripts)



        const el = document.getElementById("app");
        const innerHTML = el?.innerHTML;
        debugger;
        var editor = grapesjs.grapesjs.init({
          exportOptions: {
            type: 'data-gjs-type',
          },
          container: `#app`,
          fromElement: true,
          keepUnusedStyles: true,
          autorender: false,
          storageManager: { autoload: false },
          showOffsets: true,
          allowScripts: false,
          canvas: {
            scripts: globalScript ? [...globalScriptsSrcs, globalScript, ...this.state.dataSources.pageData?.extraScripts || []] : [...globalScriptsSrcs, ...this.state.dataSources.pageData?.extraScripts || []],
            styles: globalCss ? [globalCss, ...globalCssesLinks, this.state.dataSources.pageData?.extraCss || []] : [...globalCssesLinks, ... this.state.dataSources.pageData?.extraCss || []],
          },
          blockManager: {
            blocks: {
              'iframe': null  // This removes the default iframe block
            }
          },
          assetManager: {

            uploadFile: async (e: any) => {

              var files = e.dataTransfer ? e.dataTransfer.files : e.target?.files;
              // Implement your file upload logic here
              // For example, use FormData to send files to your server
              LoaderManager.start();
              var promises = [];
              for (let i in files) {
                const f = files[i] as File;

                if (!f.arrayBuffer) continue;

                const bf = await f.arrayBuffer()
                const data = {
                  data: new Blob([bf]),
                  fileName: f.name,
                  isAdminOnly: false
                };
                promises.push(this.fileService.uploadFile$(data, false).then(x => {

                  editor.AssetManager.add({
                    src: `/files/${this.state.dataSources.websiteData.tenantId}/${x.id}.${x.extension}`, // The URL of the uploaded image
                    // You can also include additional properties like name, type, etc.
                    fileId: x.id,
                    name: x.name,
                    type: 'image'
                  });
                }))
              }

              await Promise.all(promises);

              LoaderManager.end();
              // Send formData to your server or storage solution
            }
          },
          selectorManager: {
            componentFirst: true
          },
          styleManager: styleManager,
          plugins: [
            await import('grapesjs-plugin-forms'),
            await import('grapesjs-component-countdown'),
            await import('grapesjs-plugin-export'),
            await import('grapesjs-tooltip'),
            await import('grapesjs-preset-webpage'),
            await import("grapesjs-parser-postcss"),
            await import('grapesjs-blocks-basic'),
            await import('grapesjs-style-bg'),
            await import('./plugins/auto-complete-classes.plugin'),

          ],
          pluginsOpts: {
            'grapesjs-blocks-basic': {
              flexGrid: true
            },
            'grapesjs-tui-image-editor': {
              config: {
                includeUI: {
                  initMenu: 'filter',
                },
              }
            },
            'grapesjs-tabs': {
              tabsBlock: {
                category: 'Extra'
              }
            },
            'grapesjs-typed': {
              block: {
                category: 'Extra',
                content: {
                  type: 'typed',
                  'type-speed': 40,
                  strings: [
                    'Text row one',
                    'Text row two',
                    'Text row three',
                  ],
                }
              }
            },
            'grapesjs-preset-webpage': {
              modalImportTitle: 'Import Template',
              modalImportLabel: '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
              modalImportContent: function (editor: any) {
                return editor.getHtml() + '<style>' + editor.getCss() + '</style>'
              },
            },
          },
        });

        editor.I18n.addMessages({
          en: {
            styleManager: {
              properties: {
                'background-repeat': 'Repeat',
                'background-position': 'Position',
                'background-attachment': 'Attachment',
                'background-size': 'Size',
              }
            },
          }
        });

        var pn = editor.Panels;
        var modal = editor.Modal;
        var cmdm = editor.Commands;

        // Update canvas-clear command
        cmdm.add('canvas-clear', function () {
          if (confirm('Are you sure to clean the canvas?')) {
            editor.runCommand('core:canvas-clear')
            setTimeout(function () {
              localStorage.clear()
            }, 0)
          }
        });


        // Update canvas-clear coimport-urlmmand
        cmdm.add('import-url', async () => {
          var url = prompt('Geef de url in van de pagina die moet gekopieeerd worden. Achteraf krijg je een email wanneer hij geïntegreerd is.');
          document.loadingManager.start();
          await self.httpClient.postData(self.websiteService.getBaseUrl() + "/api/Page/Import", JSON.stringify({
            websiteId: self.state.dataSources.websiteData.id,
            websiteName: "Import website",
            websiteDescription: "Dit is een import website",
            language: "nl",
            defaultAiInput: "",
            siteDisplayName: "Import",
            pagePath: this.state.fullPath,
            sourceUrl: url
          }), true);
          document.loadingManager.end();
        });

        // Add info command
        var mdlClass = 'gjs-mdl-dialog-sm';
        var infoContainer = document.getElementById('info-panel');

        cmdm.add('open-info', function () {
          var mdlDialog = document.querySelector('.gjs-mdl-dialog');
          if (!mdlDialog) return;
          if (!infoContainer) return;
          mdlDialog.className += ' ' + mdlClass;
          infoContainer.style.display = 'block';
          modal.setTitle('About this demo');
          modal.setContent(infoContainer);
          modal.open();
          modal.getModel().once('change:open', function () {
            if (!mdlDialog) return;
            mdlDialog.className = mdlDialog.className.replace(mdlClass, '');
          })
        });


        cmdm.add('save', async () => {
          const filteredElements = Array.from(document.targetSiteDocument.querySelectorAll(`[clear-ds-on-save]`)) as Array<Element>;
          const comp = editor.DomComponents.componentsById;
          filteredElements.forEach(x => {
            const component = comp[x.getAttribute("id") as string];
            var type = x.getAttribute('data-gjs-type') as string;
            var ds = x.getAttribute('data-source') as string;

            (self.state.dataSources.setFunctions[type] || self.state.dataSources.setFunctions['default'])(component.getEl(), '');
            const p = document.selectTargetBasedOnPath(document.dataSources, ds);
            if (p.targetObj && p.key) {
              p.targetObj[p.key] = undefined;
            }

          });
          editor.components(editor.getEl().innerHTML);

          self.state.dataSources.pageData.html = editor.getWrapper().toHTML({ withProps: true });

          const data = {
            data: new Blob([editor.getJs() || ""]),
            fileName: "script.js",
            isAdminOnly: false
          };
          var result = await self.fileService.uploadFile$(data, false);
          var url = `https://${self.state.dataSources.websiteData.tenantId}.files${this.state.dataSources.environmentUrl}.itvanceplatform.com/api/files/download/${result.id}.${result.extension}`

          const style = {
            data: new Blob([editor.getCss().replace(`/files/${self.state.dataSources.websiteData.tenantId}/`, `https://${self.state.dataSources.websiteData.tenantId}.files${this.state.dataSources.environmentUrl}.itvanceplatform.com/api/files/`) || ""]),
            fileName: "style.css",
            isAdminOnly: false
          };
          var result2 = await self.fileService.uploadFile$(style, false);
          var url2 = `https://${self.state.dataSources.websiteData.tenantId}.files${this.state.dataSources.environmentUrl}.itvanceplatform.com/api/files/download/${result2.id}.${result2.extension}`


          self.state.dataSources.pageData.script = url;
          self.state.dataSources.pageData.css = url2;

          self.websiteService.savePage().then(x => {
            if (x) alert("Saved")
            else alert("failed")
          })

        });

        cmdm.add('save-website', async () => {

          self.websiteService.saveWebsite().then(x => {
            if (x) alert("Saved")
            else alert("failed")
          })

        });

        cmdm.add('publish', async () => {
          LoaderManager.start();
          const originalHtml = editor.getWrapper().toHTML({ withProps: true });
          let css = editor.getCss();

          // const toRemove = Array.from(document.targetSiteDocument.querySelectorAll(`.list-item`)) as Array<Element>;
          // toRemove.forEach((x: any) => {
          //   const component = comp[x.getAttribute("id") as string];
          //   
          //   const parent = x.parentElement && x.parentElement.tagName.toLocaleLowerCase() != 'body' ? comp[(x.parentElement as any).getAttribute("id") as string] : undefined;
          //   x.remove();
          //   parent.components(parent.getEl().innerHTML);
          // })
          const comp = editor.DomComponents.componentsById;
          const compEls = Array.from(
            document.targetSiteDocument.querySelectorAll(`[data-fetched-component]`)
          ) as Array<Element>;

          // Sort the elements by depth in the DOM tree, deepest first
          compEls.sort((a, b) => {
            const depthA = getDepth(a);
            const depthB = getDepth(b);
            return depthB - depthA; // Descending order: deepest first
          });

          // Helper function to calculate depth of an element
          function getDepth(el: Element): number {
            let depth = 0;
            while (el.parentElement) {
              depth++;
              el = el.parentElement;
            }
            return depth;
          }



          const filteredElements = Array.from(document.targetSiteDocument.querySelectorAll(`[data-list='true'], [clear-ds-on-save]`)) as Array<Element>;

          const clonedG = JSON.parse(JSON.stringify(this.state.dataSources.pageData));
          filteredElements.forEach(x => {
            if(x && x.getAttribute('data-disable-clear') === ('true')) return;
            const component = comp[x.getAttribute("id") as string];
            const parent = x.parentElement && x.parentElement.tagName.toLocaleLowerCase() != 'body' ? comp[(x.parentElement as any).getAttribute("id") as string] : undefined;
            var type = x.getAttribute('data-gjs-type') as string;
            var ds = x.getAttribute('data-source') as string;
            if (!component || !component.getEl()) return;
            (self.state.dataSources.setFunctions[type] || self.state.dataSources.setFunctions['default'])(component.getEl(), undefined);


            const p = document.selectTargetBasedOnPath(document.dataSources, ds);
            if (p.targetObj && p.key) {
              if (Array.isArray(p.targetObj[p.key])) {
                p.targetObj[p.key] = [];
                return;
              }
              p.targetObj[p.key] = undefined;
            }
            if (component.getEl().tagName.toLocaleLowerCase() === 'input' && parent) {
              // because of attributes
              parent.components(parent.getEl().innerHTML);
            } else {
              component.components(component.getEl().innerHTML);
            }
          });

          const timestamps = componentTimestamps;
          const compHtml: Map<string,  { componentName: string; html: string }> = new Map();
          const latestFetchedComponents: Map<string, { element: Element; timestamp: Date }> = new Map();

          // Iterate through components
          compEls.forEach((x) => {
            const componentId = x.getAttribute('id');
            const componentN = x.getAttribute('data-fetched-component') as string;
            if (!componentId) return;


            const lastChanged = componentTimestamps.get(componentId) || new Date(0);

            // Check if it's the latest for this fetched component name
            const existing = latestFetchedComponents.get(componentN);
            if (!existing || lastChanged > existing.timestamp) {
              // Update the latest entry for this fetched component name
              latestFetchedComponents.set(componentN, { element: x, timestamp: lastChanged });
            }
          });
          latestFetchedComponents.forEach((q) => {
            const x = q.element;
            const component = comp[x.getAttribute("id") as string];
            const componentName = x.getAttribute("data-fetched-component") as string;

            const parent = x.parentElement && x.parentElement.tagName.toLocaleLowerCase() != 'body' ? comp[(x.parentElement as any).getAttribute("id") as string] : undefined;
            var type = x.getAttribute('data-gjs-type') as string;
            var ds = x.getAttribute('data-source') as string;
            if (!component || !component.getEl()) return;
            compHtml.set(componentName, {componentName: componentName, html: x.outerHTML});
            component.components('');
            // Process the component here
          });

          self.state.dataSources.pageData.html = editor.getWrapper().toHTML({ withProps: true });

          let js = editor.getJs();
          if (js && js.length > 0) {
            const data = {
              data: new Blob([js]),
              fileName: "script.js",
              isAdminOnly: false
            };
            var result = await self.fileService.uploadFile$(data, false);
            var url = `https://${self.state.dataSources.websiteData.tenantId}.files${this.state.dataSources.environmentUrl}.itvanceplatform.com/api/files/download/${result.id}.${result.extension}`

            self.state.dataSources.pageData.script = url;
          } else {

            self.state.dataSources.pageData.script = undefined;
          }
          

          if (css && css.length > 0) {
            const style = {
              data: new Blob([css.replace(`/files/${self.state.dataSources.websiteData.tenantId}/`, `https://${self.state.dataSources.websiteData.tenantId}.files${this.state.dataSources.environmentUrl}.itvanceplatform.com/api/files/download/`)]),
              fileName: "style.css",
              isAdminOnly: false,
            };
            var result2 = await self.fileService.uploadFile$(style, false);
            var url2 = `https://${self.state.dataSources.websiteData.tenantId}.files${this.state.dataSources.environmentUrl}.itvanceplatform.com/api/files/download/${result2.id}.${result2.extension}`

            self.state.dataSources.pageData.css = url2;
          } else {

            self.state.dataSources.pageData.css = undefined;
          }

          compHtml.forEach(async x => {
            editor.setComponents(x.html);
            var html = editor.getWrapper().toHTML({ withProps: true });
            
            let js = editor.getJs();
            let scriptP = undefined;
            if (js && js.length > 0) {
              const data = {
                data: new Blob([js]),
                fileName: "script.js",
                isAdminOnly: false
              };
              var result = await self.fileService.uploadFile$(data, false);
              var url = `https://${self.state.dataSources.websiteData.tenantId}.files${this.state.dataSources.environmentUrl}.itvanceplatform.com/api/files/download/${result.id}.${result.extension}`

              scriptP = url;
            } else {

            }
            await self.websiteService.publishComponent(x.componentName, html,scriptP)
          })
          const websiteData = await self.websiteService.getLoadedWebsiteData(true);
          self.state.dataSources.websiteData.cmsCss = self.state.dataSources.pageData.css;
          websiteData.cmsCss = self.state.dataSources.pageData.css;
          await self.websiteService.saveWebsiteInstance(websiteData);
          self.websiteService.publishPage().then(x => {
            if (x) {
              (editor as Editor).setComponents(`${originalHtml}<style>${css}</style>`);

              LoaderManager.end();
              //location.href = location.href.replace("?editorEnabled=true", "");
            }
          })

        });


        pn.addButton('options', {
          id: 'save',
          className: 'fa fa-save',
          command: function () {
            editor.runCommand('save')
          },
          attributes: {
            'title': 'Save',
            'data-tooltip-pos': 'bottom',
          },
        });
        pn.addButton('options', {
          id: 'publish',
          className: 'fa fa-globe',
          command: function () {
            editor.runCommand('publish')
          },
          attributes: {
            'title': 'Publish',
            'data-tooltip-pos': 'bottom',
          },
        });

        pn.addButton('options', {
          id: 'open-info',
          className: 'fa fa-anchor',
          command: function () {
            editor.runCommand('save-website')
          },
          attributes: {
            'title': 'Menu updaten',
            'data-tooltip-pos': 'bottom',
          },
        });
        pn.addButton('options', {
          id: 'import-url',
          className: 'fa fa-cloud-download',
          command: function () {
            editor.runCommand('import-url')
          },
          attributes: {
            'title': 'Importeer website via url',
            'data-tooltip-pos': 'bottom',
          },
        });





        // pn.addButton('options', {
        //   id: 'open-copy',
        //   className: 'fa fa-question-circle',
        //   command: function () {
        //     editor.runCommand('core:copy')
        //   },
        //   attributes: {
        //     'title': 'Copy',
        //     'data-tooltip-pos': 'bottom',
        //   },
        // });
        // pn.addButton('options', {
        //   id: 'open-paste',
        //   className: 'fa fa-question-circle',
        //   command: function () {
        //     editor.runCommand('core:paste')
        //   },
        //   attributes: {
        //     'title': 'Paste',
        //     'data-tooltip-pos': 'bottom',
        //   },
        // });


        // Add and beautify tooltips
        [
          ['sw-visibility', 'Show Borders'],
          ['preview', 'Preview'],
          ['fullscreen', 'Fullscreen'],
          ['export-template', 'Export'],
          ['undo', 'Undo'],
          ['redo', 'Redo'],
          ['gjs-open-import-webpage', 'Import'],
          ['canvas-clear', 'Clear canvas']
        ]
          .forEach(function (item) {
            pn.getButton('options', item[0]).set('attributes', {
              title: item[1],
              'data-tooltip-pos': 'bottom'
            });
          });
        [
          ['open-sm', 'Style Manager'],
          ['open-layers', 'Layers'],
          ['open-blocks', 'Blocks']
        ]
          .forEach(function (item) {
            pn.getButton('views', item[0]).set('attributes', {
              title: item[1],
              'data-tooltip-pos': 'bottom'
            });
          });

        var titles = document.querySelectorAll('*[title]');

        for (var i = 0; i < titles.length; i++) {
          var elq = titles[i] as any;
          if (!elq) continue;
          var title = elq.getAttribute('title');
          title = title ? title.trim() : '';
          if (!title)
            break;
          elq.setAttribute('data-tooltip', title);
          elq.setAttribute('title', '');
        }




        const self = this;

        editor.on('update', async function () {


        });
        editor.on('load', async function () {
          var $ = grapesjs.$;
          if (editor.DomComponents.getType('table')) {
            editor.DomComponents.removeType('table');
          } if (editor.DomComponents.getType('cell')) {
            editor.DomComponents.removeType('cell');
          } if (editor.DomComponents.getType('row')) {
            editor.DomComponents.removeType('row');
          } if (editor.DomComponents.getType('tbody')) {
            editor.DomComponents.removeType('tbody');
          } if (editor.DomComponents.getType('tr')) {
            editor.DomComponents.removeType('tr');
          } if (editor.DomComponents.getType('tfoot')) {
            editor.DomComponents.removeType('thead');
          }
          const iframe = document.querySelector('[class="gjs-frame"]') as any;
          const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

          // Add an event listener for the contextmenu (right-click) event
          for (let i = 0; i < self.plugins.length; i++
          ) {
            editor = await self.plugins[i].configureEditor(editor, self.state, iframeDocument);
          }
          // todo move to plugin

          if (self.state.dataSources.websiteData?.script && self.state.dataSources.websiteData.script.length > 0 && !iframeDocument.getElementById('global-script')) {
            const script = iframeDocument.createElement('script');
            // Optional: Add a type attribute to the style element (not necessary in HTML5)
            script.type = 'application/javascript';
            script.src = `${self.state.dataSources.websiteData.script}?dontPreload=true`;
            script.id = "global-script";
            iframeDocument.body?.appendChild(script);

          }

          // if (self.state.dataSources.websiteData?.externalScripts && self.state.dataSources.websiteData.externalScripts.length > 0) {
          //   self.state.dataSources.websiteData.externalScripts.forEach(async (x: any, index: number) => {
          //     const key = `global-script-${index}`
          //     if (!iframeDocument.getElementById(key)) {

          //       const script = iframeDocument.createElement('script');
          //       // Optional: Add a type attribute to the style element (not necessary in HTML5)
          //       script.type = 'application/javascript';
          //       //script.src = `${x}?dontPreload=true`;
          //       let text = await (await fetch(`${x}?dontPreload=true`)).text();
          //       script.appendChild(document.createTextNode(text));
          //       script.id = key;
          //       iframe.onload = async function () {
          //         iframeDocument.body?.appendChild(script);
          //       }
          //     }
          //   });
          // }





          // if (globalCss) {

          //   var style = iframeDocument.createElement('link');
          //   style.id = "global-css";
          //   style.href = globalCss

          //   style.rel = 'stylesheet';
          //   iframeDocument.head.appendChild(style);
          // }


          // globalCssesLinks.forEach((obj: any, index: number) => {
          //   var link = iframeDocument.createElement('link');
          //   link.rel = 'stylesheet';
          //   link.id = `global-css-${index}`;
          //   link.href = obj;
          //   iframeDocument.head.appendChild(link);
          // })

          pn.getButton('options', 'sw-visibility').set('active', 0);

          // // Show logo with the version
          // var logoCont = document.querySelector('.gjs-logo-cont');
          // document.querySelector('.gjs-logo-version').innerHTML = 'v' + grapesjs.version;
          // var logoPanel = document.querySelector('.gjs-pn-commands');
          // logoPanel.appendChild(logoCont);


          // // Load and show settings and style manager
          // var openTmBtn = pn.getButton('views', 'open-tm');
          // openTmBtn && openTmBtn.set('active', 1);
          // var openSm = pn.getButton('views', 'open-sm');
          // openSm && openSm.set('active', 1);

          // // Remove trait view
          // pn.removeButton('views', 'open-tm');

          // // Add Settings Sector
          // var traitsSector = $('<div class="gjs-sm-sector no-select">' +
          //   '<div class="gjs-sm-sector-title"><span class="icon-settings fa fa-cog"></span> <span class="gjs-sm-sector-label">Settings</span></div>' +
          //   '<div class="gjs-sm-properties" style="display: none;"></div></div>');
          // var traitsProps = traitsSector.find('.gjs-sm-properties');
          // traitsProps.append($('.gjs-trt-traits'));
          // $('.gjs-sm-sectors').before(traitsSector);
          // traitsSector.find('.gjs-sm-sector-title').on('click', function () {
          //   var traitStyle = traitsProps.get(0).style;
          //   var hidden = traitStyle.display == 'none';
          //   if (hidden) {
          //     traitStyle.display = 'block';
          //   } else {
          //     traitStyle.display = 'none';
          //   }
          // });

          // Open block manager
          var openBlocksBtn = editor.Panels.getButton('views', 'open-blocks');
          openBlocksBtn && openBlocksBtn.set('active', 0);

          // editor.on('component:selected', () => {
          //   const openTmBtn = editor.Panels.getButton('views', 'open-tm'); // get the trait manager button
          //   openTmBtn && openTmBtn.set('active', 1); // activate the button to open the trait manager
          // });

          const getStyles = (components: any) => {
            // recurse down through components and store styles in temp attribute
            components.forEach((component: any) => {
              const recurse = (comp: any) => {
                // if component has any styling
                if (Object.keys(comp.getStyle()).length !== 0) comp.attributes.savedStyle = comp.getStyle()
                if (comp.get("components").length) {
                  comp.get("components").forEach((child: any) => {
                    recurse(child)
                  })
                }
              }
              recurse(component)
            })
            return components
          }

          const setStyles = (component: any) => {
            // recurse down and re-apply style back to components
            const recurse = (comp: any) => {
              if ('savedStyle' in comp.attributes) {
                comp.setStyle(comp.attributes.savedStyle)
                delete comp.attributes.savedStyle
              }
              if (comp.attributes.components.length) {
                comp.attributes.components.forEach((child: any) => {
                  recurse(child)
                })
              }
            }
            recurse(component)
          }


          // Retrieve the traits from the model's defaults
          document.targetSiteDocument = iframeDocument;


          const injectStylesIntoIframe = (iframeDocument: any) => {
            const styleId = 'custom-context-menu-styles';

            // Check if the style already exists to prevent duplicates
            if (!iframeDocument.getElementById(styleId)) {
              const head = iframeDocument.head || iframeDocument.getElementsByTagName('head')[0];
              const style = iframeDocument.createElement('style');
              style.id = styleId;
              style.type = 'text/css';
              style.appendChild(iframeDocument.createTextNode(`
              #grape-context-menu-link {
                position: absolute;
                z-index: 999999;
                display: none; /* Initially hidden, show it on trigger */
                border: 1px solid #c5c5c5; /* Lighter border color */
                background: white;
                width: 200px;
                box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Add some shadow for depth */
                border-radius: 4px; /* Slightly rounded corners */
                overflow: hidden; /* Ensures sub-elements don't overflow */
              }
              
              #grape-context-menu-link li {
                list-style-type: none; /* Remove bullet points */
                padding: 10px 20px; /* Add some padding */
                cursor: pointer; /* Change cursor to indicate interactivity */
                transition: background-color 0.2s ease-in-out; /* Smooth transition for hover effect */
              }
              
              #grape-context-menu-link li:hover {
                background-color: #f5f5f5; /* Lighter hover effect */
              }
              
              #grape-context-menu-link li:not(:last-child) {
                border-bottom: 1px solid #ededed; /* Add separation between items */
              }
              
              #grape-context-menu-link li a {
                text-decoration: none; /* No underline on links */
                color: #333; /* Dark text for readability */
                display: block; /* Make the whole area clickable */
              }
              
              #grape-context-menu-link li a:hover {
                color: #000; /* Slightly darker text on hover */
              }

                data-source-fetcher {
  display: none !important;
}
              
              
              `));
              head.appendChild(style);
            }
          };

          // Assuming `document.targetSiteDocument` is the target document within your iframe
          injectStylesIntoIframe(document.targetSiteDocument);

          if (innerHTML) {
            editor.setComponents(innerHTML || '');

          }


          if (self.state.dataSources.pageData?.extraScripts && self.state.dataSources.pageData?.extraScripts.length > 0) {
            // Define a function to load each script and return a promise
            const loadScript = (url: string, key: string) => {
              return new Promise((resolve, reject) => {
                const script = document.targetSiteDocument.createElement('script');
                script.type = 'application/javascript';
                // Optional: Add a type attribute to the style element (not necessary in HTML5)
                script.src = `${url}?dontPreload=true`;
                script.id = key;

                script.onload = () => {
                  //console.log(`Script loaded: ${url}`);
                  resolve({});
                };

                script.onerror = (error) => {
                  console.error(`Error loading script ${url}:`, error);
                  reject(error);
                };

                document.targetSiteDocument.body?.appendChild(script);
              });
            };

            // Map each script loading into an array of promises
            const loadPromises = self.state.dataSources.pageData.extraScripts.map((x: any, index: number) => {
              const key = `extra-script-${index}`;

              // Check if the script hasn't been loaded yet
              if (!document.targetSiteDocument.getElementById(key)) {
                return loadScript(x, key);
              } else {
                return Promise.resolve(); // If script is already loaded, resolve immediately
              }
            });

            // Wait for all scripts to be loaded
            Promise.all(loadPromises)
              .then(() => {
                //console.log('All scripts loaded successfully.');

                const event = new Event('load');
                iframe.contentWindow.dispatchEvent(event);
                // Trigger your event or perform other actions dependent on all scripts
              })
              .catch((error) => {
                console.error('Error loading one or more scripts:', error);
              });
          }
        });




        editor.render();
        resolve({});
      })
    })

  }

}



export default GrapesJs;
