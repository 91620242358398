import { Component, Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';
import { selectTargetBasedOnPath } from '../../utils/deep-proxy.function';
import { replaceStringWithDataSource } from '../../utils/replaceStringWithDataSource';

class DataSourceListPlugin implements IGrapesJsPlugin {
    /**
     *
     */
    constructor() {

    }
    alwaysStandardNavReplaceFunction(container: any, dataSource: any): boolean {
        if(container.parentElement && container.parentElement.closest('list-template')) return false;
        if (!dataSource) return false;

        // Ensure there is a container element
        if (!container) return false;

        const rootPath = container.getAttribute("data-source");

        // Identify and preserve the template element
        let template = container.querySelector(':scope > .list-template');
        let anythingChanged = false;
        if (!template) {
            
            return false;            
            template = document.createElement("div")
            anythingChanged = true;
            template.classList.add('list-template');
            template.style.display = "none";
            container.appendChild(template)
        } else {
            template.style.display = 'none';
        }


        const existingItems = Array.from(container.querySelectorAll(':scope > .list-item'));
        const itemIdMap = existingItems.map((item: any) => [item.getAttribute('data-id'), item]);
        const newOrderContainerList = [] as any[];
        if (!dataSource || !Array.isArray(dataSource)) return false;


        let noResContainer = container.querySelector('.no-result');
        if (noResContainer) {
            if (dataSource.length === 0) {

                noResContainer.style.display = 'block';
            } else {
                noResContainer.style.display = 'none';

            }
        }
        // Render items based on dataSource
        dataSource.forEach((itemData: any, newPosition: number) => {
            const itemId = itemData.id; // Assuming each item in dataSource has a unique 'id' property
            let oldPosition = itemIdMap.findIndex(q => q[0] === itemId?.toString());

            if (oldPosition !== newPosition) {
                anythingChanged = true;
            }


            // Item does not exist, clone the template to create a new item
            let newElement = template.cloneNode(true) as HTMLElement;

            newElement.classList.remove('list-template');
            newElement.classList.add('list-item');
            newElement.setAttribute('data-id', itemId);
            newElement.removeAttribute('id');
            newElement.setAttribute('data-index', newPosition?.toLocaleString());

            newElement.setAttribute('id-root', container.getAttribute("id"));
            newElement.setAttribute('type-root', "dataSourceList");
            const dataSourceItemPath = `${rootPath}.${newPosition}`;

            const dataSourceElements = newElement.querySelectorAll('[template-data-source]');

            // Iterate over each element found and set the 'data-source' attribute
            dataSourceElements.forEach((el) => {

                if ((el.id !== 'x' && el.id !== 'y') && el.closest('[data-list="true"]') && el.closest('[data-list="true"]')?.getAttribute("id") !== container.getAttribute("id") && !el.getAttribute("data-list")) {
                    
                    return;
                }
                const templateDataSource = el.getAttribute('template-data-source');
                // Concatenate the dataSourceItemPath with the template-data-source value
                const dataSourceValue = `${dataSourceItemPath}.${templateDataSource}`;
                // Set the concatenated value as the 'data-source' attribute
                //if (el.getAttribute('data-source') != dataSourceValue) {
                    el.setAttribute('data-source', dataSourceValue);

                    //anythingChanged = true;
                //}
            });





            newElement.style.display = ''; // Make sure it's visible

            newOrderContainerList.push(newElement);


        });


        if (dataSource.length < existingItems.length) {
            for (var t = dataSource.length; t < existingItems.length; t++) {
                (existingItems[t] as any).remove();
                anythingChanged = true;
            }
        }
        if (anythingChanged) {
            container.innerHTML = '';
            container.appendChild(template);

            newOrderContainerList.forEach((x: any, index: number) => {
                container.appendChild(x);
            })


        }

        container.querySelectorAll(":scope > .list-item").forEach((resultItem: any, index: number) => {
            resultItem.querySelectorAll("[template-data-source]").forEach((x: any, index: number) => {

                if (x.closest('[data-list="true"]') && x.closest('[data-list="true"]')?.getAttribute("id") !== container.getAttribute("id") && !x.getAttribute("data-list")) {
                    
                    return;
                };

                var type = x.getAttribute("data-gjs-type");
                let defaultSet = document.dataSources.setFunctions[type] ?? document.dataSources.setFunctions["default"];
                let defaultGet = document.dataSources.getFunctions[type] ?? document.dataSources.getFunctions["default"];
                if (defaultSet) {
                    const selection = selectTargetBasedOnPath(document.dataSources, x.getAttribute("data-source"));
                    if (selection.targetObj) {
                        if (selection.targetObj[selection.key] !== defaultGet(x)) {
                            defaultSet(x, selection.targetObj[selection.key]);
                            anythingChanged = true;
                        }
                    }

                }
            })



            const attributeBindings = resultItem.querySelectorAll('[data-attribute-data-source]');
            const els = [] as any[];

            // Iterate over each element found and set the 'data-source' attribute
            attributeBindings.forEach((innerEl: any) => {
                


                els.push(innerEl)
            });
            els.push(resultItem)

            els.forEach(el => {
                if (el.closest('[data-list="true"]') && el.closest('[data-list="true"]')?.getAttribute("id") !== container.getAttribute("id") && !el.getAttribute("data-list")) {
                    
                    return;
                };;
                const templateDataSource = el.getAttribute('data-attribute-data-source') || '{}';
                const dict = JSON.parse(templateDataSource);
                Object.keys(dict).forEach((x: any, index) => {
                    var attribute = x;
                    var path = dict[x];

                    const dataSourceItemPath = `${rootPath}.${resultItem.getAttribute('data-index')}`;
                    if (path.indexOf('{{') > -1) {
                        const source = document.selectTargetBasedOnPath(document.dataSources, dataSourceItemPath);

                        const result = document.replaceStringWithDataSource(path, source.targetObj[source.key], false);
                        if (result && result.length > 0) {
                            if (attribute === 'class') {
                                result.split(' ').forEach((x: any) => {
                                    if (!el.classList.contains(x)) {
                                        el.classList.add(x);
                                    }
                                });
                            } else {
                                if (
                                    el.getAttribute(attribute) !== result) {

                                    el.setAttribute(attribute, result);
                                }

                            }
                        }

                        else {
                            //el.removeAttribute(attribute);
                        }

                    } else {
                        // Concatenate the dataSourceItemPath with the template-data-source value
                        const dataSourceValue = `${dataSourceItemPath}.${path}`;
                        var t = document.selectTargetBasedOnPath(document.dataSources, dataSourceValue);
                        // Set the concatenated value as the 'data-source' attribute
                        if (t.targetObj[t.key]) {

                            if (attribute === 'class') {
                                t.targetObj[t.key].split(' ').forEach((x: any) => {
                                    if (!el.classList.contains(x)) {
                                        el.classList.add(x);
                                    }
                                });
                            } else {
                                if(el.getAttribute(attribute) !== t.targetObj[t.key]){
                                   el.setAttribute(attribute, t.targetObj[t.key]);

                               }

                            }
                        }
                        else {
                            //el.removeAttribute(attribute);
                        }
                    }

                })
            })

        });


        // Any remaining items in itemIdMap are no longer in dataSource and should be removed


        return anythingChanged;

    }
    configureAlways(state: IWebsiteState): void {
        const dataSourceListReplaceFunction = (container: any, navData: any) => {
            this.alwaysStandardNavReplaceFunction(container, navData)
        };




        // Function to generate a JSON structure from the navigation links
        const dataSourceListSelectFunction = (container: any) => {
            const items = container.querySelectorAll(':scope > .list-item');
            let array = Array.from([...items]);

            const rootPath = container.getAttribute("data-source");
            array = array

            let result = (array as any[]).map((a: any, index: number) => {

                const valueSelect = selectTargetBasedOnPath(state.dataSources, `${rootPath}.${index}`);
                if (valueSelect.targetObj && valueSelect.targetObj[valueSelect.key] && valueSelect.targetObj[valueSelect.key] !== null) {
                    return valueSelect.targetObj[valueSelect.key];
                }

                return { id: a.getAttribute("data-id") };
            });

            return result;
        };


        document.dataSources.setFunctions.dataSourceList = dataSourceListReplaceFunction;
        document.dataSources.getFunctions.dataSourceList = dataSourceListSelectFunction;
    }
    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {



        return new Promise(resolve => {
            const navReplaceFunction = (container: any, navData: any) => {

                if (this.alwaysStandardNavReplaceFunction(container, navData) && editor && editor.DomComponents) {

                    if (!navData) return false;
                    const ulId = container.getAttribute("id");
                    var component = editor.DomComponents.componentsById[ulId] as any;
                    // if (component && document.dataSources.editorEnabled) {
                    //     component.components(container.innerHTML);

                    // }
                }
            };
            const indexes = {} as any;
            const initializeCreatedA = (child: Component, parentId: string) => {

                let updates = [] as any[];

                var component = editor.DomComponents.componentsById[parentId] as any;
                if (!component) return;
                const items = component.getEl()?.querySelectorAll(':scope >.list-item') as any;
                const rootPath = component.getEl()?.getAttribute('data-source') as any;
                const array = Array.from(items);
                let newIndex = array.length;
                const domEl = child.getEl() as any;
                let id = domEl.getAttribute('data-id');
                updates.push(domEl);
                let dataSourceArray = selectTargetBasedOnPath(state.dataSources, rootPath);
                let existingElement = undefined;
                const existing = array.filter((ex: any) => ex.getAttribute("data-id") === id && ex !== domEl);
                let creation = false;
                if (existing.length > 0) {
                    domEl.setAttribute("data-id", crypto.randomUUID().replace('-', ''));
                    newIndex = array.indexOf(existing[0]) + 1;
                    domEl.setAttribute("data-index", newIndex);
                    creation = true;
                    existingElement = selectTargetBasedOnPath(state.dataSources, `${rootPath}.${array.indexOf(existing[0])}`);

                    Array.from(domEl.querySelectorAll("[template-data-source]")).forEach((x: any, y: number) => {
                        x.setAttribute("data-source", `${rootPath}.${newIndex}.${x.getAttribute("template-data-source")}`)
                        updates.push(x);
                    });
                } else {
                    existingElement = selectTargetBasedOnPath(state.dataSources, `${rootPath}.${newIndex}`);
                }
                if (!existingElement.targetObj) return;
                let newElement = { ...existingElement.targetObj[existingElement.key] };

                newElement.id = domEl.getAttribute("data-id")

                if (creation) dataSourceArray.targetObj[dataSourceArray.key].splice(newIndex, 0, newElement);



                if (creation) {
                    array.filter(x => x !== domEl).forEach((x: any, index: number) => {
                        let currentindex = newIndex;

                        if (index === currentindex) {
                            x.setAttribute("data-index", currentindex + 1);
                            var items = x.querySelectorAll("[template-data-source]")


                            if (updates.indexOf(x) === -1) {
                                updates.push(x)
                            }
                            Array.from(items).forEach((q: any, y: number) => {

                                q.setAttribute("data-source", `${rootPath}.${currentindex + 1}.${q.getAttribute("template-data-source")}`)

                                updates.push(q);
                            });

                            //indexCurrent
                            newIndex++;
                        }
                    });
                }
                updates.forEach((x: any, index: number) => {
                    const copy = {} as any;
                    // Loop through attributes of the element
                    for (var i = 0; i < x.attributes.length; i++) {
                        var attribute = x.attributes[i];
                        copy[attribute.name] = attribute.value; // Set the property value
                    }


                    var c = editor.DomComponents.componentsById[x.getAttribute("id")] as any;
                    c.setAttributes(copy);
                })


            };

            const deleteFunction = (child: Component, parentId: string) => {

                var component = editor.DomComponents.componentsById[parentId];
                if (!component) return;
                const domEl = child.getEl() as any;
                var id = domEl?.getAttribute("data-id");
                if (!id) return;
                const items = component.getEl()?.querySelectorAll(':scope > .list-item') as any;
                const rootPath = component.getEl()?.getAttribute('data-source') as any;
                const array = Array.from(items) as any[];

                var indexCurrent = array.indexOf(domEl);
                let dataSourceArray = selectTargetBasedOnPath(state.dataSources, rootPath);
                if (indexCurrent === -1) {
                    return;
                }
                dataSourceArray.targetObj[dataSourceArray.key].splice(indexCurrent, 1);
                array.forEach((x: any, index: number) => {
                    const currentindex = indexCurrent + 1;
                    if (index === currentindex) {
                        x.setAttribute("data-index", indexCurrent);
                        var items = x.querySelectorAll("[template-data-source]")

                        Array.from(items).forEach((x: any, y: number) => {
                            x.setAttribute("data-source", `${rootPath}.${indexCurrent}.${x.getAttribute("template-data-source")}`)
                        });
                        //indexCurrent
                        indexCurrent++;
                    }
                })
            };


            const dragFinishedFunction = (child: Component, dragElement: Component, parentId: string) => {
                var component = editor.DomComponents.componentsById[parentId];
                if (!component) return;
                const domEl = child.getEl ? child.getEl() as any : undefined;
                if (!domEl) return;
                var id = domEl?.getAttribute("data-id");
                if (!id) return;
                const items = component.getEl()?.querySelectorAll(':scope > .list-item') as any;
                const rootPath = component.getEl()?.getAttribute('data-source') as any;
                const array = Array.from(items) as any[];

                var indexCurrent = array.indexOf(domEl);
                let dataSourceArray = selectTargetBasedOnPath(state.dataSources, rootPath);
                let newPosition = indexCurrent;

                let updates = [] as any[];
                if (indexCurrent === -1) {

                    dataSourceArray.targetObj[dataSourceArray.key].splice(Number.parseInt(child.getEl()?.getAttribute('data-index') || ''), 1);
                    indexCurrent = Number.parseInt(child.getEl()?.getAttribute('data-index') ?? '0')
                    deleteFunction(child, parentId);

                    array.filter(x => x !== domEl).forEach((x: any, index: number) => {
                        let currentindex = indexCurrent;

                        if (index === currentindex) {
                            x.setAttribute("data-index", indexCurrent);
                            var items = x.querySelectorAll("[template-data-source]")


                            if (updates.indexOf(x) === -1) {
                                updates.push(x)
                            }
                            Array.from(items).forEach((q: any, y: number) => {

                                q.setAttribute("data-source", `${rootPath}.${indexCurrent}.${q.getAttribute("template-data-source")}`)

                                updates.push(q);
                            });

                            //indexCurrent
                            indexCurrent++;
                        }
                    });
                } else {

                    const el = dataSourceArray.targetObj[dataSourceArray.key][Number.parseInt(child.getEl()?.getAttribute('data-index') || '')];

                    dataSourceArray.targetObj[dataSourceArray.key].splice(Number.parseInt(child.getEl()?.getAttribute('data-index') || ''), 1);
                    dataSourceArray.targetObj[dataSourceArray.key].splice(indexCurrent, 0, el);
                    indexCurrent = Math.min(Number.parseInt(child.getEl()?.getAttribute('data-index') ?? '0'), indexCurrent);

                    array.forEach((x: any, index: number) => {
                        let currentindex = indexCurrent;

                        if (index === currentindex) {
                            x.setAttribute("data-index", index);
                            var items = x.querySelectorAll("[template-data-source]")


                            if (updates.indexOf(x) === -1) {
                                updates.push(x)
                            }
                            Array.from(items).forEach((q: any, y: number) => {

                                q.setAttribute("data-source", `${rootPath}.${index}.${q.getAttribute("template-data-source")}`)

                                updates.push(q);
                            });

                            //indexCurrent
                            indexCurrent++;
                        }
                    });
                }



                updates.forEach((x: any, index: number) => {
                    const copy = {} as any;
                    // Loop through attributes of the element
                    for (var i = 0; i < x.attributes.length; i++) {
                        var attribute = x.attributes[i];
                        copy[attribute.name] = attribute.value; // Set the property value
                    }


                    var c = editor.DomComponents.componentsById[x.getAttribute("id")] as any;
                    c.setAttributes(copy);
                })
            };


            document.dataSources.initializeCreated.dataSourceList = initializeCreatedA;
            document.dataSources.setFunctions.dataSourceList = navReplaceFunction;
            document.dataSources.removeElementFunction.dataSourceList = deleteFunction;
            document.dataSources.dragFinishedFunction.dataSourceList = dragFinishedFunction;

            editor.DomComponents.addType('dataSourceList', {
                isComponent: (el: any) => {
                    if (el?.getAttribute) {
                        const va = el?.getAttribute('data-list') === 'true';

                        if (va) {

                            return true;
                        }

                        return el?.getAttribute('data-list') === 'true';
                    }

                    return false;
                },
                model: {
                    defaults: {
                        attributes: {
                            'data-list': 'true'
                        }
                    }
                }
            });

            
            // Resolve the promise with the editor object after configuration
            resolve(editor);
        });
    }
}

export default DataSourceListPlugin;