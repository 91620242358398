import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';

class SelectDataSource implements IGrapesJsPlugin {
    /**
     *
     */
    constructor() {

    }
    private readonly types = ['select'];

    configureAlways(state: IWebsiteState): void {
        const inputReplaceFunction = (el: any, value: any): void => {
            const options = el.options;
            el.value = value;
            let found = false;
            for (let i = 0; i < options.length; i++) {
                
                if (options[i].value === value || (options[i].value === '' && !value)) {
                    el.selectedIndex = i;
                    options[i].setAttribute("selected", true);
                    found = true;
                }else{
                    
                    options[i].removeAttribute("selected");
                }
            }
            // Optionally, clear selection if value not found
            if(!found)
                el.selectedIndex = -1;
        }

        const inputSelectFunction = (el: any) => {
            return el.value === '' ? undefined : el.value;
        };

        this.types.forEach((x: string) => {


            document.dataSources.setFunctions[x] = inputReplaceFunction;
            document.dataSources.getFunctions[x] = inputSelectFunction;
        });
    }


    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {

        return new Promise(resolve => {
            this.types.forEach((x: string) => {
                editor.DomComponents.addType(x, {
                    model: {
                        defaults: {
                            ...editor.DomComponents.getType(x)?.model.prototype.defaults,
                            script: function () {

                                const element = this;

                                element.addEventListener('change', (event: any) => {
                                    
                                    const path = this.getAttribute("data-source");

                                    let {
                                        targetObj,
                                        key
                                    } = document.selectTargetBasedOnPath(document.dataSources, path);

                                    const newValue = event.target.value;
                                    if (!targetObj) {

                                        const segments = path.split('.');
                                        segments.pop(); // Remove the last element
                                        const parentPath = segments.join('.');
                                        let parent = document.selectTargetBasedOnPath(document.dataSources, parentPath);
                                        parent.targetObj[parent.key] = {};


                                        const newP = document.selectTargetBasedOnPath(document.dataSources, path);
                                        targetObj = newP.targetObj;
                                        key = newP.key;
                                    }
                                    targetObj[key] = newValue || '';
                                });
                            }
                        }
                    }
                });
            });
            // Resolve the promise with the editor object after configuration
            resolve(editor);
        });
    }


}

export default SelectDataSource