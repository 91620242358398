import { IWebsiteState } from '../models/website-state.model';
import HttpClient from '../utils/httpclient.service';
import { File, MultipleFiles } from './file.model';


export class FileService {
  constructor(
    private httpService: HttpClient,
    private websiteState: IWebsiteState) { }
  getBaseUrl(): string {

    
    return `https://${this.websiteState.dataSources.websiteData.tenantId}.files${this.websiteState.dataSources.environmentUrl}.itvanceplatform.com`
  }

  getFile$(fileID: string): Promise<File> {
    return this.httpService.fetchData(`${this.getBaseUrl()}/Api/Files/${fileID}`);
  }

  searchFiles$(referenceId: string, containingTags: string[], searchAfterParams?: string[], pageSize = 10): Promise<MultipleFiles> {

    let url = `${this.getBaseUrl()}/Api/Files/search?`;
    url = `${url}`
    if (searchAfterParams) {
      searchAfterParams.forEach(element => {

        url = `${url}&searchAfterParams=${element.toString()}`
      });
    }
    if (containingTags) {
      containingTags.forEach(element => {

        url = `${url}&containingTags=${element.toString()}`
      });
    }

    if (referenceId) {
      
      url = `${url}&refereneId=${referenceId.toString()}`
    }

    return this.httpService.fetchData(url);
  }

  uploadFile$(file: any, isAdminOnly = false, isPublic = true): Promise<File> {
    const formData = new FormData();
    
    
    formData.append('uploadedFile', file.data);

    formData.append('fileName', file.fileName);
    formData.append('isAdminOnly', isAdminOnly ? 'true' : 'false');

    if (file.referenceId) {
      formData.append('referenceId', file.referenceId);
    }
    if (file.id) {
      formData.append('id', file.id);
    }

    formData.append('isPublic', isPublic && !isAdminOnly ? "true" : "false");

    Object.keys(file).forEach(x => {
        if(x !== 'data' && x !== "fileName" && x !== "referenceId" && x !== "id" ){
          formData.append(x, file[x]);
        }
    })
    file.tags?.forEach((tag:any) => {
      formData.append('tags', tag);
    });
    return this.httpService.postFormData(`${this.getBaseUrl()}/Api/Files/upload`, formData);
  }

  uploadOrOverwriteFile$(file: any, isAdminOnly = false, isPublic = true): Promise<File> {
    const formData = new FormData();
    
    
    formData.append('uploadedFile', file.data);

    formData.append('fileName', file.fileName);
    formData.append('isAdminOnly', isAdminOnly ? 'true' : 'false');

    if (file.referenceId) {
      formData.append('referenceId', file.referenceId);
    }
    if (file.id) {
      formData.append('id', file.id);
    }

    formData.append('isPublic', isPublic && !isAdminOnly ? "true" : "false");

    Object.keys(file).forEach(x => {
        if(x !== 'data' && x !== "fileName" && x !== "referenceId" && x !== "id" ){
          formData.append(x, file[x]);
        }
    })
    file.tags?.forEach((tag:any) => {
      formData.append('tags', tag);
    });
    return this.httpService.postFormData(`${this.getBaseUrl()}/Api/Files/uploadOrOverwrite`, formData);
  }

  updateFile$(file: any, isAdminOnly = false, isDeleted = false): Promise<File> {
    const formData = new FormData();
    formData.append('uploadedFile', file.data);
    formData.append('fileName', file.fileName);
    formData.append('deleted', isDeleted ? 'true' : 'false');
    formData.append('isAdminOnly', isAdminOnly ? 'true' : 'false');
    formData.append('id', file.id);

    if (file.referenceId) {
      formData.append('referenceId', file.referenceId);
    }

    file.tags.forEach((tag:any) => {
      formData.append('tags', tag);
    });
    return this.httpService.postData(`${this.getBaseUrl()}/Api/Files/update`, formData);
  }
}
