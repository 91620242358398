import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';
import HttpClient from '../../utils/httpclient.service';
import { LoaderManager } from '../../utils/loader/loader';
import { replaceStringWithDataSource } from '../../utils/replaceStringWithDataSource';

class DataSourceFetcherPlugin implements IGrapesJsPlugin {
    /**
     *
     */
    constructor(private httpClient: HttpClient) {

    }

    configureAlways(state: IWebsiteState): void {
        if(!state.dataSources.websiteData || state.editorEnabled) return;
        (state.dataSources.websiteData.prefetchedDataSources ||[]).forEach((element:any) => {
            let func = (next: () => void) => {
                if (!document.loadingManager || !document.dataSources) return;

                if((document.dataSources.initialClientRendering && document.dataSources.isClientSide)){
                    document.addEventListener("PageLoaded", (e) => {
                        if(document.dataSources.initialClientRendering) return;
                        
                    document.loadingManager.start();
                        const outputPath = element.bindedOutputModel;                        
                        const y = document.selectTargetBasedOnPath(document.dataSources, outputPath);
                        y.targetObj[y.key] = {...y.targetObj[y.key]};
                        
                    document.loadingManager.end();
                    })
                }

                document.loadingManager.start();
                const postModel = element.bindedInputModel;
                const outputPath = element.bindedOutputModel;

                let targetUri = element.action;
                const method = element.method;
                const sendAsForm = element.sendAsFormData;
                if(!element.action || element.action.length === 0){
                    return;
                }
                targetUri = document.replaceStringWithDataSource(targetUri, document.dataSources, false);


                const {
                    targetObj,
                    key
                } = document.selectTargetBasedOnPath(document.dataSources, postModel);


                const t = targetObj[key];

                const y = document.selectTargetBasedOnPath(document.dataSources, outputPath);
                if (sendAsForm?.toString().toLocaleLowerCase() === '') {
                    document.httpClient.postFormDataObject(targetUri, t).then((x: any) => {


                        next();
                        document.loadingManager.end();
                    }).catch((x:any) => {
                        
                        y.targetObj[y.key] = undefined;
                        next();
                        document.loadingManager.end();
                    });;
                } else if (method === 'post') {
                    document.httpClient.postData(targetUri, t).then((x: any) => {
                        y.targetObj[y.key] = x;
                       
                        next();
                        document.loadingManager.end();
                    }).catch((x:any) => {          
                        y.targetObj[y.key] = undefined;
                        

                        next();
                        document.loadingManager.end();
                    });;
                } else {
                    document.httpClient.fetchData(targetUri, t).then((x: any) => {
                        y.targetObj[y.key] = x;
                        
                        next();
                        document.loadingManager.end();
                    }).catch((x:any) => {       
                                     
                        next();
                        document.loadingManager.end();
                    });
                }
            }
            
            
            //if (document.dataSources && (document.dataSources.initialClientRendering && document.dataSources.isClientSide)) return;

           
            func(() =>{});

        });
    }

    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {

        const self = this;
        return new Promise(resolve => {
            editor.DomComponents.addType("http-json-data-binding", {
                isComponent: el => {
                    return el.tagName?.toLocaleLowerCase() === 'data-source-fetcher';
                },
                model: {
                    attributes: {
                        "clear-ds-on-save": true,
                        
                    },
                    defaults: {
                        ...(editor.DomComponents.getType("http-json-data-binding") as any)?.model?.prototype?.defaults ?? [],
                        traits: [
                            {
                                type: 'text',
                                label: 'Bind to event trigger',
                                name: 'binded-event',
                                placeholder: '(eg: PageLoaded)'
                            },
                            {
                                type: 'text',
                                label: 'Input model binding',
                                name: 'binded-input-model',
                                placeholder: 'Enter data source identifier...'
                            }, {
                                type: 'text',
                                label: 'Output model',
                                name: 'binded-output-model',
                                placeholder: 'Enter data source identifier...'
                            }, {
                                type: 'checkbox',
                                label: 'Clear display on save',
                                name: 'clear-ds-on-save',
                                placeholder: 'Enter data source identifier...'
                            }, {
                                type: 'checkbox',
                                label: 'Formdata',
                                name: 'send-as-form-data',
                                placeholder: 'Enter data source identifier...'
                            }, {
                                type: 'checkbox',
                                label: 'Trigger on load',
                                name: 'trigger-on-load',
                                placeholder: 'Enter data source identifier...'
                            },  {
                                type: 'checkbox',
                                label: 'Confirm action',
                                name: 'needs-confirmation',
                                placeholder: 'Confirm action'
                            }, 
                            
                            {
                                type: 'select',
                                name: 'method',
                                options: [
                                    { value: 'get', name: 'GET' },
                                    { value: 'post', name: 'POST' },
                                ],
                            }, {
                                name: 'action',
                            },
                            ...(editor.DomComponents.getType("http-json-data-binding") as any)?.model?.prototype?.defaults?.traits ?? []

                        ],
                        script: function () {

                            const element = this;


                            if(!element.getAttribute("action") || element.getAttribute("action").length === 0){
                                return;
                            }
                            const bindedEvent = element.getAttribute("binded-event");

                            const confirmationNeeded = element.getAttribute("needs-confirmation");
                            let func = (next: () => void) => {
                                if (!document.loadingManager || !document.dataSources || (document.dataSources.initialClientRendering && document.dataSources.isClientSide)) return;

                                document.loadingManager.start();
                                const postModel = element.getAttribute("binded-input-model");
                                const outputPath = element.getAttribute("binded-output-model");

                                let targetUri = element.getAttribute("action");
                                const method = element.getAttribute("method");
                                const sendAsForm = element.getAttribute("send-as-form-data");

                                targetUri = document.replaceStringWithDataSource(targetUri, document.dataSources, false);


                                const {
                                    targetObj,
                                    key
                                } = document.selectTargetBasedOnPath(document.dataSources, postModel);


                                const t = targetObj[key];

                                const y = document.selectTargetBasedOnPath(document.dataSources, outputPath);
                                if (sendAsForm?.toString().toLocaleLowerCase() === '') {
                                    document.httpClient.postFormDataObject(targetUri, t).then((x: any) => {


                                        y.targetObj[y.key] = x;
                                        next();
                                        document.loadingManager.end();
                                    });
                                } else if (method === 'post') {
                                    document.httpClient.postData(targetUri, t).then((x: any) => {
                                        y.targetObj[y.key] = x;
                                        next();
                                        document.loadingManager.end();
                                    });
                                } 
                                else if (method === 'delete') {
                                    document.httpClient.delete(targetUri, t).then(() => {
                                        next();
                                        document.loadingManager.end();
                                    });
                                }                                 
                                else {
                                    document.httpClient.fetchData(targetUri, t).then((x: any) => {
                                        y.targetObj[y.key] = x;
                                        next();
                                        document.loadingManager.end();
                                    });
                                }
                            }
                            
                            
                            if (document.dataSources && (document.dataSources.initialClientRendering && document.dataSources.isClientSide)) return;

                            if (element.getAttribute("trigger-on-load") === '') {
                                func(() =>{});
                            }

                            if(!bindedEvent) return;
                            

                            const observer = new MutationObserver((mutations) => {
                                mutations.forEach(mutation => {
                                  mutation.removedNodes.forEach((node:any) => {
                                    if (node.id === element.id) {
                                      // The element was removed from the DOM
                                      document.removeEventListener(bindedEvent, handler);
                                      observer.disconnect();  // Stop observing since the job is done
                                    }
                                  });
                                });
                              });
                              
                              observer.observe(document.body, { childList: true, subtree: true });
                              
                              function handler(event:any) {
                                if(confirmationNeeded?.toString().toLocaleLowerCase() === ''){
                                    var sure = confirm("Zeker?");
                                    if(!sure) return;
                                }

                                func(event.detail?.onComplete || (() => {}));
                              }
                              
                              document.addEventListener(bindedEvent, handler);
                        }
                    }

                }
            });
            // Resolve the promise with the editor object after configuration
            resolve(editor);
        });
    }
}

export default DataSourceFetcherPlugin;