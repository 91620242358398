import { IWebsiteState } from '../models/website-state.model';

import { createDeepProxyDataSourceListener, dateReviver, getCookie } from '../utils/deep-proxy.function';
import { LoaderManager } from '../utils/loader/loader';


export function configureState(): IWebsiteState {
    var renderCheckDiv = document.getElementById('data') as Element;
    const isClientSide = (renderCheckDiv && renderCheckDiv.getAttribute('data-server-rendered') === 'true') || false;
    let fullPath = location.href.substring(location.href.indexOf('/') + 1);
    fullPath = fullPath.substring(fullPath.indexOf('/') + 1);
    fullPath = fullPath.substring(fullPath.indexOf('/'));
    let currentPath = fullPath.substring(fullPath.indexOf('/') + 1);
    const envUrl = renderCheckDiv.getAttribute("data-environment-url");
    currentPath = currentPath.substring(currentPath.indexOf('/'));
    var origFqdn = getCookie("original-fqdn");
    const state: IWebsiteState = renderCheckDiv.getAttribute('state-data') ?
        JSON.parse(renderCheckDiv.getAttribute('state-data') as string, dateReviver) :
        {
            language: location.href.split('/')[3],
            currentPath: currentPath.split('?')[0].replace(/\/+$/, ""),
            fullPathWithQuery: fullPath,
            fullPath: fullPath.split("?")[0],
            fqdn: origFqdn ? origFqdn : location.host,
            api: `https://itvance.cms${envUrl}.itvanceplatform.com`,
            dataSources: {
                queryStrings: Object.fromEntries(new URLSearchParams(location.search).entries()),
                isClientSide: isClientSide,
                isServerSide: !isClientSide,
                editorEnabled: false,
                environmentUrl: envUrl,
                setFunctions: {

                },
                getFunctions: {

                },
                initializeCreated: {

                },
                removeElementFunction: {

                },
                dragFinishedFunction: {

                },
                attributes: {},
                componentWithDataSourceUpdateViaGrape: {},
                websiteData: undefined,
                pageData: undefined
            }
        };
    state.dataSources.isClientSide = isClientSide;
    state.dataSources.isServerSide = !isClientSide;
    state.dataSources.initialClientRendering = true;
    state.dataSources.currentPath = currentPath.split('?')[0].replace(/\/+$/, "");
    state.dataSources.editorEnabled = state.dataSources.queryStrings.editorEnabled === 'true'
    state.dataSources = createDeepProxyDataSourceListener(state.dataSources, document);

    if (renderCheckDiv) {
        renderCheckDiv.setAttribute('data-server-rendered', 'true');
    }

    document.dataSources = state.dataSources;
    document.targetSiteDocument = document;
    document.state = state;
    document.loadingManager = LoaderManager;
    return state;
}
