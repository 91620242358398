import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';
import { getCookie, selectTargetBasedOnPath } from '../../utils/deep-proxy.function';
class DataSourceTraitsPlugin implements IGrapesJsPlugin {
    /**
     *
     */
    constructor() {

    }
    // Helper function to parse date strings (assumes input in "DD/MM/YYYY HH:mm" format)
    parseDate(dateStr: any): Date | boolean {
        const regex = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})$/;
        const match = dateStr.match(regex);
        if (match) {
            // Create a new Date object from the parsed components
            const [_, day, month, year, hour, minute] = match;
            return new Date(`${year}-${month}-${day}T${hour}:${minute}:00`);
        }
        return false;
    }


    formatDate(date: Date, lang: string, options: Intl.DateTimeFormatOptions): string {
        return date.toLocaleString(lang, options || {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,

        });
    }

    configureAlways(state: IWebsiteState): void {
        const defaultReplaceFunction = (el: any, value: any) => {

            let newValue = value as string;

            if (value instanceof Date) {
                let dateFormat = el.getAttribute("data-date-format-options");
                newValue = this.formatDate(value, getCookie('culture') || 'default', dateFormat ? JSON.parse(dateFormat) : undefined);
            }
            
            let dsHtml = el.getAttribute("data-source-html");
            if(dsHtml){
                if (el.innerHTML != newValue) {
                    el.innerHTML = newValue;
                }
                return;
            }
            if (el.innerText != newValue) {
                el.innerText = newValue;
            }
        };

        const defaultSelectFunction = (el: Element) => {

            let res = el.textContent;

            let dateFormat = el.getAttribute("data-date-format");
            if (dateFormat) {
                const c = document.selectTargetBasedOnPath(document.dataSources, el.getAttribute('data-source') as string);
                return c.targetObj[c.key];
            }
            //if (date) return date;
            return res;
        }
        state.dataSources.setFunctions.default = defaultReplaceFunction;
        state.dataSources.getFunctions.default = defaultSelectFunction;
    }
    configureEditor(editor: Editor | any, state: IWebsiteState, doc: Document): Promise<Editor> {

        return new Promise(resolve => {


            doc.dataSources = state.dataSources;
            doc.selectTargetBasedOnPath = document.selectTargetBasedOnPath;
            doc.replaceStringWithDataSource = document.replaceStringWithDataSource;
            doc.httpClient = document.httpClient;
            doc.fileService = document.fileService;
            doc.loadingManager = document.loadingManager;



            const attributesChanged = (el: any, val: any) => {

                var component = editor.DomComponents.componentsById[el.id];
                component.setAttributes(val);
            };

            state.dataSources.attributesChanged = attributesChanged;
            ['nav', 'link', 'text', 'input', 'select', 'textarea', 'dataSourceList', 'label', 'checkbox', 'fileselect'].forEach((x: string) => {
                editor.DomComponents.addType(x, {
                    model: {
                        defaults: {
                            ...(editor.DomComponents.getType(x) as any)?.model?.prototype?.defaults ?? [],
                            traits: [
                                {
                                    type: 'text',
                                    label: 'Data Source',
                                    name: 'data-source',
                                    placeholder: 'Enter data source identifier...'
                                }, {
                                    type: 'checkbox',
                                    label: 'Clear display on save',
                                    name: 'clear-ds-on-save',
                                },
                                ...(editor.DomComponents.getType(x) as any)?.model?.prototype?.defaults?.traits ?? []

                            ]
                        }

                    }
                });
            });

            editor.on('rte:disable', (component: any) => {

                const dataSourcePath = (component.el.attributes['data-source'] || {}).value;
                if (!dataSourcePath) return;
                const comp = editor.DomComponents.componentsById;
                Object.keys(comp).forEach((key, y, z) => {
                    const component = comp[key] as any;
                    if (component && component.attributes && component.attributes.attributes && component.attributes
                        .attributes['data-source'] && component.attributes.attributes['data-source'].startsWith(
                            dataSourcePath)) {
                        component.components(component.getEl().innerHTML);
                    }
                });
            });

            const componentDataSourceDictionary = {} as any;
            const initialRendering = {} as any;

            editor.on('component:update', (component: any) => {
                if (!component.getEl()) return;
                const dataSourcePath = component.getEl().getAttribute('data-source');
                const storeAttributes = component.getEl().getAttribute('store-attributes');

                if (storeAttributes == "true") {

                    state.dataSources.attributes[component.ccid] = component.attributes.attributes;
                }


                let dataSourceChanged = false;

                if (dataSourcePath != componentDataSourceDictionary[component.ccid]) {
                    dataSourceChanged = true;
                    componentDataSourceDictionary[component.ccid] = dataSourcePath;
                }


                const {
                    targetObj,
                    key
                } = selectTargetBasedOnPath(state.dataSources, dataSourcePath);
                const type = component.getEl().getAttribute("data-gjs-type");
                if (targetObj) {
                    if (dataSourceChanged) {

                        (state.dataSources.setFunctions[type] || state.dataSources.setFunctions[
                            'default'])(component.getEl(), targetObj[key])
                        component.components(component.getEl().innerHTML)

                    } else if (dataSourcePath) {

                        targetObj[key] = (state.dataSources.getFunctions[type] || state.dataSources.getFunctions[
                            'default'])(component.getEl());
                    }
                }

            });
            editor.on('component:mount', (component: any) => {
                const storeAttributes = ((component.attributes || {}).attributes || {})['store-attributes'];

                if (storeAttributes == true) {

                    state.dataSources.attributes[component.ccid] = component.attributes.attributes;
                }

                let dataSourcePath =
                    ((component.attributes || {}).attributes || {})['data-source'];
                const typeHit = component.getEl()?.classList?.contains("list-item");

                if (dataSourcePath || typeHit) {
                    const type = component.getEl().getAttribute("type-root");
                    const id = component.getEl().getAttribute("id-root");
                    if (type) {
                        const f = (state.dataSources.initializeCreated[type] || state.dataSources.initializeCreated[
                            'default']);
                        if (f) {

                            if (!initialRendering[component.ccid]) {

                                f(component, id)
                                initialRendering[component.ccid] = dataSourcePath;

                                return;
                            }
                        }
                    }

                }

                dataSourcePath =
                    ((component.attributes || {}).attributes || {})['data-source'];

                if (dataSourcePath) {

                    componentDataSourceDictionary[component.ccid] = dataSourcePath;
                    const {
                        targetObj,
                        key
                    } = selectTargetBasedOnPath(state.dataSources, dataSourcePath);
                    const type = component.getEl().getAttribute("data-gjs-type");
                    if (targetObj) {
                        (state.dataSources.setFunctions[type] || state.dataSources.setFunctions[
                            'default'])(component.getEl(), targetObj[key])

                    }
                }

            });

            editor.on('component:remove', (component: any) => {
                const storeAttributes = ((component.attributes || {}).attributes || {})['store-attributes'];

                if (storeAttributes == true) {

                    state.dataSources.attributes[component.ccid] = component.attributes.attributes;
                }

                if (!component.getEl()?.getAttribute) return;
                const type = component.getEl()?.getAttribute("type-root");
                const id = component.getEl()?.getAttribute("id-root");
                if (type) {
                    const f = (state.dataSources.removeElementFunction[type] || state.dataSources.removeElementFunction[
                        'default']);
                    if (f) {

                        //f(component, id);

                    }
                } 
                
                
                //initialRendering[component.ccid] = undefined;
                //componentDataSourceDictionary[component.ccid] = undefined;

            });

            editor.on('component:drag:end ', (component: any) => {

                const storeAttributes = ((component.target.attributes || {}).attributes || {})['store-attributes'];

                if (storeAttributes == true) {

                    state.dataSources.attributes[component.target.ccid] = component.target.attributes.attributes;
                }
                let bindedAttribute = component.target;

                let dataSourcePath =
                    ((bindedAttribute.attributes || {}).attributes || {})['data-source'];
                let typeRoot = undefined;
                let idRoot = undefined;
                while (!dataSourcePath && bindedAttribute) {
                    if (((bindedAttribute.attributes || {}).attributes || {})['data-source']) {
                        dataSourcePath =
                            ((bindedAttribute.attributes || {}).attributes || {})['data-source'];
                    }


                    if (((bindedAttribute.attributes || {}).attributes || {})['type-root'])
                        typeRoot = ((bindedAttribute.attributes || {}).attributes || {})['type-root']
                            ;
                    if (((bindedAttribute.attributes || {}).attributes || {})['id-root'])
                        idRoot =
                            ((bindedAttribute.attributes || {}).attributes || {})['id-root'];
                    if (dataSourcePath) {
                        break;
                    }
                    bindedAttribute = bindedAttribute.components()?.models[0];
                }
                if (dataSourcePath) {

                    if (typeRoot) {
                        const f = (state.dataSources.dragFinishedFunction[typeRoot] || state.dataSources.dragFinishedFunction[
                            'default']);
                        if (f) {

                            f(bindedAttribute, component, idRoot)
                        }
                    }
                } else if (typeRoot && idRoot) {
                    const f = (state.dataSources.dragFinishedFunction[typeRoot] || state.dataSources.dragFinishedFunction[
                        'default']);
                    if (f) {

                        f(component.target, component, idRoot)
                    }
                }



            });


            // Resolve the promise with the editor object after configuration
            resolve(editor);
        });
    }



}

export default DataSourceTraitsPlugin