
import { UserManager, User as OidcUser, WebStorageStateStore, AsyncStorage, SignoutResponse } from 'oidc-client-ts';
import { IWebsiteState } from '../models/website-state.model';
import { CookieStorage } from 'cookie-storage';
import { deleteCookie, getCookie, setCookie } from '../utils/deep-proxy.function';
import CookieStorageStateStore from './cookieStorage';

interface User {
  username: string;
  id: string;
  isAdmin: boolean;
  // Add other user properties
}

class OAuth {
  private tokenStorageKey: string = 'oauth_token';
  userManager: UserManager;

  constructor(clientId: string, redirectUri: string, silentRedirectUri: string, logoutRedirect: string, providerUri: string, scope: string, private state: IWebsiteState) {
    this.userManager = new UserManager({
      authority: providerUri, // The URL of your identity server
      client_id: clientId, // The client ID assigned to you by the identity server
      response_type: 'code', // Use the authorization code flow with PKCE
      scope,
      redirect_uri: redirectUri, // The URL for the redirect after login
      post_logout_redirect_uri: logoutRedirect,

      silent_redirect_uri: silentRedirectUri,
      automaticSilentRenew: true,                // Enable silent renew
      accessTokenExpiringNotificationTimeInSeconds: 3600,   // Warn 60 seconds before token expiry
      userStore: new CookieStorageStateStore(state.dataSources.environmentUrl) as any,
    });

    this.userManager.events.addAccessTokenExpiring(async () => {
      try {
        console.log("Access token is expiring. Attempting silent renew...");
        const user = await this.userManager.signinSilent();
        console.log("Token refreshed:", user?.access_token);
        localStorage.setItem("access_token", user?.access_token || '');
      } catch (error) {
        console.error("Silent refresh failed:", error);
      }
    });
    
  }
  // Initiates the OAuth flow by redirecting to the OAuth provider's URL
  public async authorize(): Promise<void> {
    if (this.state.dataSources.isClientSide) {

      let referrer = location.href;
      if (referrer.includes('/login')) {
        referrer = this.state.previousFullPathWithQuery as string;
      }

      if (referrer && !referrer.includes('/login')) {
        localStorage.setItem("login-redirect", referrer);
      } else { // if (!referrer || referrer.includes('/login'))
        localStorage.setItem("login-redirect", "/");
      }
      await this.userManager?.signinRedirect();
    }
  }

  // Parses the hash returned by OAuth provider to extract the token
  public handleCallback(): Promise<void> {

    if (this.state.dataSources.isServerSide || !this.userManager) {
      return new Promise<void>(resolve => resolve());
    }
    return this.userManager.signinCallback(location.href).then(async (user) => {


    }).catch(function (error) {
      console.error('Authentication error', error);
      // Handle errors, for example, show an error message
    });
  }// Parses the hash returned by OAuth provider to extract the token
  public handleSilentCallback(): Promise<void> {

    if (this.state.dataSources.isServerSide || !this.userManager) {
      return new Promise<void>(resolve => resolve());
    }
    return this.userManager.signinSilentCallback(location.href).then(async (user) => {


    }).catch(function (error) {
      console.error('Authentication error', error);
      // Handle errors, for example, show an error message
    });
  }public silentLogin(): Promise<void> {

    if (this.state.dataSources.isServerSide || !this.userManager) {
      return new Promise<void>(resolve => resolve());
    }
    return this.userManager.signinSilent().then(async (user) => {


    }).catch(function (error) {
      console.error('Authentication error', error);
      // Handle errors, for example, show an error message
    });
  }

  // Saves the token to localStorage or some other storage mechanism
  private setToken(token: string) {
    //document.setItem(this.tokenStorageKey, token);
  }

  // Retrieves the token from storage
  public getToken(): Promise<string | undefined> {

    return this.getUser().then(x => {
      return x?.access_token
    });

  }

  public logoutCallback(): Promise<void> {
    if (this.state.dataSources.isServerSide || !this.userManager) {
      return new Promise<void>(resolve => resolve());
    }
    return this.userManager.signoutCallback().catch(function (error) {
      console.error('Authentication error', error);
      // Handle errors, for example, show an error message
      location.href = "/"
    }) as any;
  }
  private async getUser(): Promise<OidcUser | null> {

    var res = await this.userManager.getUser();


    return res;

  }
  // Decodes the JWT token to get the user information

  public async getUserFromToken(): Promise<User | null> {
    return await this.getUser().then(x => {
      if (!x) return x as (User | null)
      var roles = x?.profile['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] as Array<any> || [];
      var id = x?.profile['sub'] as string;

      return {
        username: x?.profile.name || x?.profile.username,
        isAdmin: roles.filter(q => q === 'admin').length > 0,
        id
      } as (User | null);
    });
  }

  // Log the user out
  public async logout() {
    if (!this.state.dataSources.isClientSide) return;

    await this.userManager.signoutRedirect();
    // await this.userManager?.signoutRedirect({
    //   post_logout_redirect_uri: "https"+location.host+"/auth/logoutCallback"
    // });
  }

}
// Usage:
// const oauthClient = new OAuth('your-client-id', 'your-redirect-uri', 'your-provider-uri');
// If the current URL contains the access token, handle the callback
// if (window.location.hash.includes('access_token')) {
//   oauthClient.handleCallback();
// }

export default OAuth;
