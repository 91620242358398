import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';
class GalleryPlugin implements IGrapesJsPlugin {
    /**
     *
     */
    constructor() {

    }

    configureAlways(state: IWebsiteState): void {
        state.dataSources.componentWithDataSourceUpdateViaGrape.text = true;
    }

    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {
        return new Promise(resolve => {
            ["gallery"].forEach((x: string) => {
                editor.DomComponents.addType(x, {
                    isComponent: el => {
                        return el?.getAttribute && !!el?.getAttribute("data-gallery");
                    },
                    model: {
                        defaults: {
                            ...editor.DomComponents.getType(x)?.model.prototype.defaults,

                            script: function () {
                                const element = this;
                                document.getElementById(element.id as string)?.querySelectorAll("a").forEach((anchor) => {
                                    const img = anchor.querySelector("img");
                                    if (!img) return; // Skip if no img found within the anchor

                                    let imageUrl = img.getAttribute("src")?.split('?')[0] as string;
                                    // Using maxWidth query parameter to request different image sizes from the server
                                    let srcSet = imageUrl;
                                    let tempImg = new Image();
                                    tempImg.onload = function () {
                                        // You would need the image dimensions for the full-size image
                                        // Ideally, these dimensions should match the actual dimensions of the image served for maxWidth=1024
                                        // Replace these with actual image dimensions if available
                                        let imageWidth = tempImg.width; // Placeholder width of the full-size image
                                        let imageHeight = tempImg.height;// Placeholder height of the full-size image

                                        anchor.setAttribute('href', imageUrl); // Set href to the full-size image URL
                                        anchor.removeAttribute('data-pswp-width')
                                        anchor.removeAttribute('data-pswp-height')
                                        anchor.removeAttribute('data-pswp-srcset');
                                        anchor.setAttribute('data-pswp-srcset', srcSet); // Set srcset for responsive image sources
                                        anchor.setAttribute('data-pswp-width', imageWidth.toString()); // Set the width of the full-size image
                                        anchor.setAttribute('data-pswp-height', imageHeight.toString()); // Set the height of the full-size image
                                    }

                                    tempImg.src = imageUrl; // Start loading the image
                                    img.setAttribute("src", `${imageUrl}?maxWidth=500&maxHeight=500`); // Reset src to remove any query parameters
                                    //img.setAttribute("srcset", srcSet); // Also set srcset on the img tag for better responsiveness outside of PhotoSwipe
                                    //img.setAttribute("sizes", "(max-width: 1024px) 100vw, 1024px"); // Example sizes attribute, adjust as needed
                                    //img.removeAttribute("sizes");
                                });
                                if (document.dataSources && document.dataSources.editorEnabled) return;
                                
                                if (!document.getElementById('photo-swipe-lightbox-css')) {
                                    const link = document.createElement('link');
                                    link.id = 'photo-swipe-lightbox-css'; // Set the id so it can be checked for existence later
                                    link.rel = 'stylesheet'; // Set the relationship to indicate it's a stylesheet
                                    link.type = 'text/css'; // Set the type to CSS
                                    link.href = 'https://cdnjs.cloudflare.com/ajax/libs/photoswipe/5.4.2/photoswipe.min.css'; // Set the URL of your CSS file here

                                    document.head.appendChild(link);
                                }
                                const moduleScriptContent = `
                                    import PhotoSwipeLightbox from 'https://cdnjs.cloudflare.com/ajax/libs/photoswipe/5.4.2/photoswipe-lightbox.esm.min.js';
                                    
                                    const lightbox = new PhotoSwipeLightbox({
                                      gallery: '#${element.id}',
                                      children: 'a',
                                    
                                    initialZoomLevel: 'fit',
                                        pswpModule: () => import('https://cdnjs.cloudflare.com/ajax/libs/photoswipe/5.4.2/photoswipe.esm.min.js')
                                    });
                                    lightbox.init();
                                  `;

                                // Create a blob from the script content
                                const blob = new Blob([moduleScriptContent], { type: 'text/javascript' });

                                // Create a URL for the blob
                                const scriptURL = URL.createObjectURL(blob);

                                // Create a new script element
                                const scriptElement = document.createElement('script');
                                scriptElement.type = 'module';
                                scriptElement.src = scriptURL;

                                // Append the script element to the body
                                element.parentNode.appendChild(scriptElement);





                            }
                        }
                    }
                });
            });


            // Resolve the promise with the editor object after configuration
            resolve(editor);
        });
    }
}

export default GalleryPlugin;