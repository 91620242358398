import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';

class ArrowSlidingToolbar implements IGrapesJsPlugin {
    /**
     *
     */
    constructor() {

    }
    
    configureAlways(state: IWebsiteState): void {
    }

    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {
        return new Promise(resolve => {
           //data-action-override
            // Resolve the promise with the editor object after configuration

           
          const arrow = document.createElement('div');
          arrow.id = 'panel-arrow';
          arrow.innerHTML = '↓'; // or any arrow icon you prefer
          document.querySelector('.gjs-pn-panels')?.appendChild(arrow);

          const panels = document.querySelectorAll('.gjs-pn-panel');
          const viewsCommands = document.querySelectorAll('.gjs-pn-views');

          //arrow.addEventListener('mouseover', () => togglePanels(false));
          arrow.addEventListener('click', () => togglePanels(false));

          viewsCommands.forEach(e => {

            //e.addEventListener('mouseover', () => showPanels(true));
            e.addEventListener('click', () => showPanels(true));
          })


         

          function showPanels(viewPanel: boolean = false) {
            
            panels.forEach((panel: any) => {
              if (!panel.classList.contains('gjs-pn-views-container') || viewPanel) {
                panel.classList.add('active');
              }
            });

            //arrow.style.display = "none";

          }


          function togglePanels(viewPanel: boolean = false) {
            let anyPanelActive = false;
          
            // Check if any panel is currently active
            panels.forEach((panel: any) => {
              if (panel.classList.contains('active')) {
                anyPanelActive = true;
              }
            });
          
            if (anyPanelActive) {
              // If panels are active, hide them
              panels.forEach((panel: any) => {
                panel.classList.remove('active');
              });
              //arrow.style.display = "flex";
            } else {
              // If no panels are active, show them
              panels.forEach((panel: any) => {
                if (!panel.classList.contains('gjs-pn-views-container') || viewPanel) {
                  panel.classList.add('active');
                }
              });
              //arrow.style.display = "none";
            }
          }
          

          function hidePanels() {
            
            panels.forEach((panel: any) => {
              
                panel.classList.remove('active');
            });

            arrow.style.display = "flex";

          }
          // editor.on('component:selected', () => {
          //   hidePanels()
          // });


          resolve(editor);
        });
    }
}

export default ArrowSlidingToolbar;