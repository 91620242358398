import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';

class ImagePlugin implements IGrapesJsPlugin {
    /**
     *
     */
    constructor() {

    }

    configureAlways(state: IWebsiteState): void {
        state.dataSources.componentWithDataSourceUpdateViaGrape.text = true;

        document.addEventListener("DataSourceChanged", (e) => {
         
            const elementsWithXorY = document.querySelectorAll('[data-src]');

            // Example usage
            elementsWithXorY.forEach((element:any) => {
                                    const src = element.getAttribute("data-src");
                                    if (!src) return;

                                    
                                    const result = document.replaceStringWithDataSource(src, document.dataSources, true);
                                    if (result && result.length > 0 && result !== element.getAttribute("src")) {

                                        element.setAttribute("src", result);

                                    }
            });
        });
    }

    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {
        return new Promise(resolve => {
            ["image"].forEach((x: string) => {
                editor.DomComponents.addType(x, {
                    model: {
                        defaults: {
                            ...editor.DomComponents.getType(x)?.model.prototype.defaults,
                            traits: [
                                ...editor.DomComponents.getType(x)?.model.prototype.defaults?.traits || [],
                                {
                                    type: 'text',
                                    label: 'Src',
                                    name: 'src',
                                    placeholder: 'Src'
                                }, {
                                    type: 'text',
                                    label: 'Src template',
                                    name: 'data-src',
                                    placeholder: 'Src template'
                                },
                            ]
                        }
                    }
                });
            });
            // Resolve the promise with the editor object after configuration
            resolve(editor);
        });
    }
}

export default ImagePlugin;