import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';

declare var grecaptcha: any;
class Recaptcha implements IGrapesJsPlugin {
    /**
     *
     */
    constructor() {

    }
    configureAlways(state: IWebsiteState): void {
        state.dataSources.componentWithDataSourceUpdateViaGrape.link = true;
    }
    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {



        return new Promise(resolve => {
            editor.DomComponents.addType("captcha-google", {
                isComponent: (el: any) => {
                    return el.tagName?.toLocaleLowerCase() === 'captcha-google';
                },
                model: {

                    attributes: {
                        "clear-ds-on-save": true
                    },
                    defaults: {
                        ...(editor.DomComponents.getType("captcha-google") as any)?.model?.prototype?.defaults ?? [],

                        tagName: 'captcha-google',
                        traits: [
                            {
                                type: 'text',
                                label: 'Bind to model',
                                name: 'binded-model',
                                placeholder: 'Enter data source identifier...'
                            },

                            ...(editor.DomComponents.getType("captcha-google") as any)?.model?.prototype?.defaults?.traits ?? []

                        ],
                        script: function () {
                            if(document?.dataSources?.isServerSide) return;
                            const element = this;
                                if (document.getElementById("recaptcha-script")) {
                                    document.getElementById("recaptcha-script")?.remove();
                                }
                                const script = document.createElement('script');
                                element.setAttribute("data-callback", `onSubmit${element.id}`);
                                // Optional: Add a type attribute to the style element (not necessary in HTML5)
                                script.type = 'application/javascript';
                                script.src = "https://www.google.com/recaptcha/api.js?render=explicit";
                                script.async = true;
                                script.id = "recaptcha-script"
                                script.defer = true;
                                
                                script.onload = () => {
                                    // Assuming `onSubmit` function is globally defined and accessible
                                    grecaptcha.ready(() => {
                                        var captchaWidgetId = grecaptcha.render(element.id, {
                                            'sitekey': element.getAttribute("data-sitekey"),
                                            'theme': 'light',
                                            'callback': (token: string) => {

                                                const path = element.getAttribute("binded-model");
        
                                                const {
                                                    targetObj,
                                                    key
                                                } = document.selectTargetBasedOnPath(document.dataSources, path);
        
                                                targetObj[key] = token;
                                                // Your logic here
                                            } // Pass the function reference directly
                                        });
                                    });

                                };

                                element.parentNode.appendChild(script);
                        }
                    }

                }
            });
            // Resolve the promise with the editor object after configuration
            resolve(editor);
        });
    }


}

export default Recaptcha;