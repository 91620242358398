import { Editor } from 'grapesjs';
import { IGrapesJsPlugin } from '../../models/grapesjs-plugin.model';
import { IWebsiteState } from '../../models/website-state.model';
import HttpClient from '../../utils/httpclient.service';
import { LoaderManager } from '../../utils/loader/loader';
import { replaceStringWithDataSource } from '../../utils/replaceStringWithDataSource';

class FormPlugin implements IGrapesJsPlugin {
    /**
     *
     */
    constructor(private httpClient: HttpClient) {

    }

    configureAlways(state: IWebsiteState): void {
        const defaultReplaceFunction = (el: any, value: any) => {

            
        };

        const defaultSelectFunction = (el: Element) => {

            const c = document.selectTargetBasedOnPath(document.dataSources, el.getAttribute('data-source') as string);
            return c.targetObj[c.key];
        }
        state.dataSources.setFunctions.form = defaultReplaceFunction;
        state.dataSources.getFunctions.form = defaultSelectFunction;
    }

    configureEditor(editor: Editor, state: IWebsiteState, doc: Document): Promise<Editor> {

        const self = this;
        return new Promise(resolve => {
            editor.DomComponents.addType("form", {
                model: {

                    attributes: {
                        "clear-ds-on-save": true
                    },
                    defaults: {
                        ...(editor.DomComponents.getType("form") as any)?.model?.prototype?.defaults ?? [],
                        traits: [
                            {
                                type: 'text',
                                label: 'Data Source',
                                name: 'binded-model',
                                placeholder: 'Enter data source identifier...'
                            },
                            {
                                type: 'text',
                                label: 'Succes bericht',
                                name: 'success-message',
                                placeholder: 'Mail successvol verstuurd!'
                            }, {
                                type: 'checkbox',
                                label: 'Clear display on save',
                                name: 'clear-ds-on-save',
                                placeholder: 'Enter data source identifier...'
                            }, {
                                type: 'checkbox',
                                label: 'Send as form data',
                                name: 'send-as-form',
                                // Change the value on checkbox change
                                changeProp: 1,
                                valueTrue: true, // Value when checked
                                valueFalse: false, // Value when not checkedF

                            },
                            ...(editor.DomComponents.getType("form") as any)?.model?.prototype?.defaults?.traits ?? []

                        ],
                        script: function () {

                            const element = this;

                            element.addEventListener('submit', (event: any) => {

                                event.preventDefault();
                                const path = element.getAttribute("binded-model");
                                const method = element.getAttribute("method");
                                const sendAsForm = element.getAttribute("send-as-form");
                                const successMessage = element.getAttribute("success-message");


                                function isVisible(elem: HTMLElement) {
                                    return !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
                                }

                                // Scan the children for the first visible submit button with a data-action-override attribute
                                const submitButtons = Array.from(element.querySelectorAll('button[type="submit"], input[type="submit"]')) as HTMLElement[];
                                let actionOverride;
                                for (const button of submitButtons) {
                                    if (isVisible(button)) {
                                        actionOverride = button.getAttribute('data-action-override');
                                        if (actionOverride) break; // Found a visible button with the override, exit the loop
                                    }
                                }

                                // Use the data-action-override attribute if it exists and is set, otherwise use the form's action attribute
                                let targetUri;
                                if (actionOverride) {
                                    const myEvent = new CustomEvent(actionOverride, {
                                        detail: { 
                                            onComplete: () =>{
                                                if(successMessage && successMessage.length > 0){
                                                    element.innerHTML = `<p>${successMessage}</p>`;
                                                }

                                            } 
                                        }
                                    });
                                    document.dispatchEvent(myEvent);
                                    return;
                                    // break early, use binded datasource
                                } else {
                                    targetUri = element.getAttribute('action');
                                }
                                targetUri = replaceStringWithDataSource(targetUri, state);


                                if (method !== 'post') return;
                                const {
                                    targetObj,
                                    key
                                } = document.selectTargetBasedOnPath(document.dataSources, path);


                                const t = targetObj[key];

                                document.loadingManager.start();
                                if (sendAsForm?.toString().toLocaleLowerCase() === '') {
                                    document.httpClient.postFormDataObject(targetUri, t).then((x: any) => {
                                        if (x) {
                                            if(successMessage){
                                                element.innerHTML = `<p>${successMessage}</p>`;
                                            }
                                            document.loadingManager.end();
                                        }

                                        alert("Er ging iets mis");
                                    });
                                } else {
                                    document.httpClient.postData(targetUri, t).then((x: any) => {
                                        if (x) {
                                            if(successMessage){
                                                element.innerHTML = `<p>${successMessage}</p>`;
                                            }
                                            document.loadingManager.end();
                                        }

                                        alert("Er ging iets mis");
                                    });
                                }
                            });
                        }
                    }

                }
            });
            // Resolve the promise with the editor object after configuration
            resolve(editor);
        });
    }
}

export default FormPlugin;