import { IWebsiteState, PageData, SiteData } from '../../models/website-state.model';
import HttpClient from '../../utils/httpclient.service';

class SiteDataLoadingService {
    
    private websiteData: Promise<SiteData> | undefined;
    private pageData: any = {};

    constructor(private httpClient: HttpClient, private state: IWebsiteState) {

    }
    getBaseUrl(): string {

        return this.state && this.state.dataSources.websiteData && this.state.dataSources.websiteData.tenantId ? `https://${this.state.dataSources.websiteData.tenantId}.cms${this.state.dataSources.environmentUrl}.itvanceplatform.com` : this.state.api
    }

    startLoadingWebsiteData(state: IWebsiteState) {
        
        if (state.dataSources.websiteData) {
            this.websiteData = new Promise<SiteData>(resolve => {
                resolve(state.dataSources.websiteData);
            });
            this.pageData[`${state.language}_${state.currentPath}`] = new Promise<SiteData>(resolve => {
                resolve(state.dataSources.pageData);
            });
        }
        else if (!this.websiteData) {

            const globalData = this.httpClient.fetchData(`${this.getBaseUrl()}/api/site?fqdn=${state.fqdn}&path=${encodeURIComponent(state.fullPath)}`);
            this.websiteData = globalData.then(x => {
                
                return x.websiteData as SiteData
            });

            this.pageData[`${state.language}_${state.currentPath}`] = globalData.then(x => {
                return x.pageData as PageData;
            });
        }
    }

    async getLoadedWebsiteData(reload: boolean= false): Promise<SiteData> {
        if(reload){
            var res =  await this.httpClient.fetchData(`${this.getBaseUrl()}/api/site?fqdn=${this.state.fqdn}&path=${encodeURIComponent(this.state.fullPath)}`);
            return res.websiteData;
        }
        if (this.state.dataSources.websiteData) {
            return { ...this.state.dataSources.websiteData };
        }

        return await this.websiteData as any;
    }
    async getLoadedPageData(): Promise<PageData> {
        let pageData = undefined;
        if(this.state.dataSources.pageData){
            pageData = this.state.dataSources.pageData; 
        }else{
            pageData = await this.pageData[`${this.state.language}_${this.state.currentPath}`] as any;
        }

        
        return pageData;
    }

    async savePage(): Promise<PageData> {

        if (this.state.isNewPage) {
            let page = await this.httpClient.postData(`${this.getBaseUrl()}/api/page`, {...this.state.dataSources.pageData, websiteId: this.state.dataSources.websiteData.id, tenantId:this.state.dataSources.websiteData.tenantId, language: this.state.language});
            this.httpClient.postData(`${this.getBaseUrl()}/api/WebsitePageBinding`, {
                websiteId: this.state.dataSources.websiteData.id,
                tenantId: this.state.dataSources.websiteData.tenantId,
                pageId: page.id,
                path: this.state.fullPath,
                language: this.state.language
            });
            this.state.dataSources.pageData = page;
            return page;
        }

        const page = await this.httpClient.postData(`${this.getBaseUrl()}/api/page/${this.state.dataSources.pageData.id}/${this.state.dataSources.pageData.language}`, this.state.dataSources.pageData);
        
        this.state.dataSources.pageData = page;
        return page;
    }
    async saveWebsite(): Promise<PageData> {
        return this.httpClient.postData(`${this.getBaseUrl()}/api/website/${this.state.dataSources.websiteData.id}`, this.state.dataSources.websiteData)
    }
    async saveWebsiteInstance(websiteData : any): Promise<PageData> {
        return this.httpClient.postData(`${this.getBaseUrl()}/api/website/${this.state.dataSources.websiteData.id}`, websiteData)
    }

    async publishPage(): Promise<PageData> {

        if (this.state.isNewPage) {
            let page = await this.httpClient.postData(`${this.getBaseUrl()}/api/page`, {...this.state.dataSources.pageData, websiteId: this.state.dataSources.websiteData.id, tenantId:this.state.dataSources.websiteData.tenantId, language: this.state.language});
            this.httpClient.postData(`${this.getBaseUrl()}/api/WebsitePageBinding`, {
                websiteId: this.state.dataSources.websiteData.id,
                tenantId: this.state.dataSources.websiteData.tenantId,
                pageId: page.id,
                path: this.state.fullPath,
                language: this.state.language
            });
            const pageRes =  await this.httpClient.postData(`${this.getBaseUrl()}/api/page/${page.id}/${page.language}/update-and-publish`, page);

        
            this.state.dataSources.pageData = pageRes;
            return pageRes;
        }
        const page =  await this.httpClient.postData(`${this.getBaseUrl()}/api/page/${this.state.dataSources.pageData.id}/${this.state.dataSources.pageData.language}/update-and-publish`, this.state.dataSources.pageData)

        
        this.state.dataSources.pageData = page;
        return page;
    }

    async publishComponent(name: string, html: string,  js?: string): Promise<PageData> {
        let d= { id: name,websiteId: this.state.dataSources.websiteData.id, tenantId:this.state.dataSources.websiteData.tenantId, language: this.state.dataSources.pageData.language, html: html, script: js};
        const ex = await this.httpClient.fetchData(`${this.getBaseUrl()}/api/page/${name}/${this.state.dataSources.pageData.language}`);
        
        if(!ex || !ex.id){
             await this.httpClient.postData(`${this.getBaseUrl()}/api/page`, d);
             return this.httpClient.postData(`${this.getBaseUrl()}/api/WebsitePageBinding`, {
                websiteId: this.state.dataSources.websiteData.id,
                tenantId: this.state.dataSources.websiteData.tenantId,
                pageId: name,
                path: `/${this.state.language}/${name}`,
                language: this.state.language
            });
        }
            
        const page =  await this.httpClient.postData(`${this.getBaseUrl()}/api/page/${name}/${this.state.dataSources.pageData.language}/update-and-publish`,d)

        
        return page;
    }
    async getComponent(componentName: any) {
        if (this.pageData[`${this.state.language}_comp_${componentName}`]) {
            return this.pageData[`${this.state.language}_comp_${componentName}`] as PageData
        }


        this.pageData[`${this.state.language}_comp_${componentName}`] = this.httpClient.fetchData(`${this.getBaseUrl()}/api/page/${componentName}/${this.state.language}`).then(x => {
            return x as PageData
        });

        return await this.pageData[`${this.state.language}_comp_${componentName}`] ;

    }
    async loadPage(state: IWebsiteState) {
        if(this.state.dataSources.pageData){
            return this.state.dataSources.pageData;
        }
        if (this.pageData[`${state.language}_${state.currentPath}`]) {
            return this.pageData[`${state.language}_${state.currentPath}`] as PageData
        }


        this.pageData[`${state.language}_${state.currentPath}`] = this.httpClient.fetchData(`${this.getBaseUrl()}/api/page?fqdn=${state.fqdn}&path=${encodeURIComponent(state.fullPath)}`).then(x => {
            return x as PageData
        });

    }

}

export default SiteDataLoadingService